import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable } from "@angular/core";

import { Color } from "@angular-material-components/color-picker";

import { PhoneTypesEnum } from 'src/app/shared/models/phone-types.enum';
import { Auxiliary } from "../../helpers/auxiliary";

@Injectable({
    providedIn: 'root'
})
export class FormValidatorService {
    private static urlRegularExpression = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    private static cpfRegularExpression = /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/;
    private static cnhRegExp = /[0-9]{9}-?[0-9]{2}/;
    private static regExplicensePlate = /[A-Z]{3}\d([A-Z]|\d)\d{2}/;
    // eslint-disable-next-line max-len
    private static emailRegularExpression = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    private static cnpjRegularExpression =
        /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;

    static url(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value;

        if (
            (
                Auxiliary.isString(inputValue) && inputValue.trim().length &&
                !FormValidatorService.urlRegularExpression.test(inputValue) &&
                !inputValue.startsWith('mailto:')
            ) || inputValue?.includes(' ')
        ) return {url: true};

        return null;
    }

    static cnpj(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value?.replace(/\D/g, '');

        if (
            Auxiliary.isString(inputValue) && inputValue.trim().length !== 14 &&
            (
                !FormValidatorService.cnpjRegularExpression.test(inputValue) ||
                inputValue.match(/\d/).length !== 14
            )
        ) return {cnpj: true};

        return null;
    }

    static hourMinute(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value?.replace(/\D/g, '');

        if (Auxiliary.isString(inputValue) && inputValue.length && inputValue?.match(/\d/g)?.length !== 4)
            return {hourMinute: true};

        return null;
    }

    static cpf(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value?.replace(/\D/g, '');

        if (Auxiliary.isString(inputValue) && inputValue.length && !FormValidatorService.cpfRegularExpression.test(inputValue))
            return {cpf: true};

        return null;
    }

    static cnh(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value?.replace(/\D/g, '');

        if (Auxiliary.isString(inputValue) && inputValue?.length && !FormValidatorService.cnhRegExp.test(inputValue)) return {cnh: true};

        return null;
    }

    static licensePlate(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value;

        if (
            Auxiliary.isString(inputValue) &&
            inputValue?.length &&
            (!FormValidatorService.regExplicensePlate.test(inputValue) || inputValue.length !== 7)
        ) return {licensePlate: true};

        return null;
    }

    static cpfOrEmail(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value;

        if (
            Auxiliary.isString(inputValue) && inputValue.trim().length &&
            (
                !FormValidatorService.cpfRegularExpression.test(inputValue) ||
                inputValue.length < 11 ||
                inputValue.length > 14 ||
                inputValue.match(/\d/g).length !== 11 ||
                inputValue.match(/-/g).length > 1 ||
                inputValue.match(/\./g).length > 2
            ) &&
            !FormValidatorService.emailRegularExpression.test(inputValue)
        ) return {cpfOrEmail: true};

        return null;
    }

    static phone(type: PhoneTypesEnum): ValidationErrors | null {
        return (control: AbstractControl) => {
            const inputValue = control?.value?.trim();
            let regularExpression = /\./;

            switch (type) {
                case PhoneTypesEnum.PATTERN_BRAZIL_WITH_DDD:
                    regularExpression = /^[(]?[1-9]{2}[)]?[\s]?[0-9]{4,5}[-]?[0-9]{4}$/;
                    break;
                case PhoneTypesEnum.PATTERN_BRAZIL_WITH_DDD_ONLY_CELLPHONE:
                    regularExpression = /^[(]?[1-9]{2}[)]?[\s]?[0-9]{5}[-]?[0-9]{4}$/;
                    break;
            }

            if (
                Auxiliary.isString(inputValue) && inputValue.length &&
                !regularExpression.test(inputValue)
            ) return {phone: true};

            return null;
        };
    }

    static color(abstractControl: AbstractControl): ValidationErrors | null {
        if (
            !(abstractControl.value instanceof Color) &&
            !Auxiliary.isNull(abstractControl.value) &&
            !Auxiliary.isUndefined(abstractControl.value)
        ) return {color: true};

        return null;
    }

    static cep(abstractControl: AbstractControl): ValidationErrors | null {
        const inputValue = abstractControl?.value;

        if (
            Auxiliary.isString(inputValue) && inputValue.trim().length &&
            !/\d{5}[-]?\d{3}/.test(inputValue)
        ) return {cep: true};

        return null;
    }

    static dateRangeErrorSeparated(firstField: FormControl, secondField: FormControl, isStart: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const firstValue = firstField?.value?.toDate?.() ?? firstField?.value;
            const secondValue = secondField?.value?.toDate?.() ?? secondField?.value;

            if (
                firstField &&
                secondField &&
                control &&
                firstField.touched &&
                secondField.touched &&
                control.touched &&
                control.dirty
            ) {
                if (
                    control?.validator?.({} as AbstractControl)?.required
                    &&
                    (
                        (Auxiliary.isNull(control.value) && (firstValue && !secondValue || !firstValue && secondValue))
                        ||
                        (firstValue && secondValue && firstValue > secondValue)
                    )
                ) return {dateRangeMessageSeparated: true};
                else {
                    if (isStart && !secondField.valid) secondField.updateValueAndValidity({onlySelf: true, emitEvent: false});
                    else if (!firstField.valid) firstField.updateValueAndValidity({onlySelf: true, emitEvent: false});

                    return null;
                }
            }

            return null;
        };
    }

    static minLengthArray(minLength: number = 0): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control?.value;

            if (Auxiliary.isArray(value) && value.length < minLength) return {minLengthArray: true};

            return null;
        };
    }
}
