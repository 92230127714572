import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { ThemePalette } from "@angular/material/core";

@Component({
    selector: 'acc-progress-spinner',
    templateUrl: './progress-spinner.component.html',
    styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent{
    @Input() mode: ProgressSpinnerMode = 'indeterminate';
    @Input() value = 0;
    @Input() color: ThemePalette;
    @Input() strokeWidth = 0;
    @Input() diameter = 0;
    @Input() condition = true;
    @Input() txt = '';

    classes = 'progress-spinner';

    @Input('classes')
    set setClasses(classes: string){
        if(classes) this.classes = `${this.classes} ${classes}`;
    }
}
