import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";
import { Auxiliary } from "../helpers/auxiliary";
import {
    MaterialPaletteExporterService
} from "./material-pallete-generator/services/material-palette-exporter.service";
import {
    MaterialPaletteGeneratorService
} from "./material-pallete-generator/services/material-palette-generator.service";
import { HexColor } from "./material-pallete-generator/models/hex-color.model";


@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    readonly PRIMARY_KEY = 'primary-color-palette';
    readonly SECONDARY_KEY = 'secondary-color-palette';

    private readonly PRIMARY_PREFIX = 'mdc-theme-primary-';
    private readonly SECONDARY_PREFIX = 'mdc-theme-secondary-';
    private readonly SUFFIX_CT = '-ct';
    private readonly PRINCIPAL_CODE_COLOR = '500';
    private isBrowser: boolean;

    constructor(
        private materialPaletteExporterService: MaterialPaletteExporterService,
        private materialPaletteGeneratorService: MaterialPaletteGeneratorService,
        @Inject(PLATFORM_ID) platformId: number
    ){
        this.isBrowser = isPlatformBrowser(platformId);
        this.init();
    }

    createPalette(hex: string): HexColor[]{
        return this.materialPaletteGeneratorService.generatePaletteFromHex(hex);
    }

    setApplicationTheme(key: string, colors: HexColor[]): void{
        if(!colors || !colors.length) return;

        let prefix = '';

        switch(key){
            case this.PRIMARY_KEY:
                prefix = this.PRIMARY_PREFIX;
                break;
            case this.SECONDARY_KEY:
                prefix = this.SECONDARY_PREFIX;
                break;
        }

        this.setHexColorInStorage(key, colors);

        const rootElement = document.querySelector(':root') as HTMLElement;

        const pascalCaseColors = colors.map((color: any) => (
            {
                ContrastColor: color.contrastColor || color.ContrastColor,
                Color: color.color || color.Color,
                Name: color.name || color.Name
            }
        ));

        const colorsVariables = this.materialPaletteExporterService
                                    .toCssVars(pascalCaseColors, prefix, this.SUFFIX_CT)
                                    .replace(/(.+{|})|(\s+)|(\\n)/g, '')
                                    .split(';')
                                    .filter(Boolean);

        colorsVariables.forEach((color: string) => {
            const entriesValue = color.split(':');
            const variableName = entriesValue[0];
            const variableValue = entriesValue[1];

            rootElement.style.setProperty(variableName, variableValue);

            if(variableName.includes(this.PRINCIPAL_CODE_COLOR) && !variableName.includes(this.SUFFIX_CT)){
                const variable = variableName.replace(`-${this.PRINCIPAL_CODE_COLOR}`, '');

                rootElement.style.setProperty(variable, variableValue);
                rootElement.style.setProperty(`${variable}-rgb`, Auxiliary.hexToRgb(variableValue).slice(0, 3).join(', '));
            }
        });
    }

    private init(): void{
        if(!this.isBrowser) return;

        this.setApplicationTheme(this.PRIMARY_KEY, this.getHexColorFromStorage(this.PRIMARY_KEY));
        this.setApplicationTheme(this.SECONDARY_KEY, this.getHexColorFromStorage(this.SECONDARY_KEY));
    }

    private getHexColorFromStorage(key: string): HexColor[]{
        return JSON.parse(localStorage.getItem(key) || '[]');
    }

    private setHexColorInStorage(key: string, colors: HexColor[]): void{
        localStorage.setItem(key, JSON.stringify(colors));
    }
}
