import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

// @ts-ignore
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask';
import { Auxiliary } from '../../helpers/auxiliary';
import { Generic } from '../../models/generic';

@Directive({
  selector: '[accFormDate]'
})
export class FormDateDirective implements OnDestroy, OnInit{
    maskedInputController: any;

    private textMask: Generic = {
        mask: [],
        showMask: false,
        guide: false,
    };

    constructor(private element: ElementRef){}

    @Input('accFormDate')
    set setTextMask(mask: any){
        if(mask) this.textMask = Auxiliary.assignAllObjects(this.textMask, mask);
    }

    ngOnInit(): void {
        this.maskedInputController = textMask.maskInput({ inputElement: this.element.nativeElement, ...this.textMask });
    }

    ngOnDestroy() {
        this.maskedInputController?.destroy?.();
    }
}
