import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from "@ngx-translate/core";

import { ZoomImgComponent } from './zoom-img.component';

@NgModule({
    declarations: [
        ZoomImgComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class ZoomImgModule{}
