import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { take } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";


@Component({
	selector: 'acc-dynamic-icon',
	templateUrl: './dynamic-icon.component.html',
	styleUrls: ['./dynamic-icon.component.scss']
})
export class DynamicIconComponent implements AfterViewInit {

	@Input() iconName = 'question_mark';
	@Input() color: string;
	@Input() iconUrl: string;

	@ViewChild('div', { static: false }) div: ElementRef;

	canShowIconFromUrl = false;

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private render: Renderer2
	) {
	}



	ngAfterViewInit() {
		if(!this.iconUrl) return;

		const url = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.api + this.iconUrl);

		const icon$ = this.matIconRegistry.getSvgIconFromUrl(url);

		icon$.pipe(take(1)).subscribe((resp: SVGElement) => {
			if (this.color) resp.style.color = this.color;
			this.render.appendChild(this.div.nativeElement, resp);
			this.canShowIconFromUrl = true;
		}, (err) => {
			this.canShowIconFromUrl = false;
		});
	}

}
