import { NativeDateAdapter } from "@angular/material/core";
import { Injectable } from "@angular/core";

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Injectable({providedIn: "root"})
export class FormDateAdapterService extends NativeDateAdapter {
    format(date: Date): string {
        return moment(date).format('DD/MM/YYYY');
    }

    parse(value: any): Date | null {
        if (!moment(value, 'DD/MM/YYYY', true).isValid()) return this.invalid();

        return moment(value, 'DD/MM/YYYY', true).toDate();
    }
}
