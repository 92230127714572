import {Injectable} from '@angular/core';

import {MenuBreadcrumb} from "../../../shared/models/menu-breadcrumb";

@Injectable({
    providedIn: 'root'
})
export class MenuBreadcrumbService {
    private breadcrumbList: MenuBreadcrumb[] = [];

    constructor() {
    }

    set breadcrumb(breadcrumb: MenuBreadcrumb[]) {
        this.breadcrumbList = breadcrumb;
    }

    get breadcrumb(): MenuBreadcrumb[] {
        return this.breadcrumbList;
    }

    get antepenultimateItem(): MenuBreadcrumb {
        return this.breadcrumbList?.[this.breadcrumbList.length - 2];
    }

    get lastItem(): MenuBreadcrumb {
        return this.breadcrumbList?.[this.breadcrumbList.length - 1];
    }

    setActiveBreadcrumb(breadcrumb: MenuBreadcrumb): void {
        const lastItem = this.breadcrumbList?.[this.breadcrumbList.length - 1];

        if (lastItem) this.breadcrumbList[this.breadcrumbList.length - 1] = Object.assign(lastItem, breadcrumb);
    }
}
