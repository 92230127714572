<div class="local-actions"
     [ngClass]="{'local-actions--in-tabs': isTabs()}"
     *ngIf="areThereLocalActions()">
    <acc-form-button *ngFor="let action of localActions"
                     [click]="!action.href ? action?.click?.bind(this) : null"
                     [href]="action?.href"
                     [icon]="action?.icon"
                     [color]="action?.color"
                     [iconColor]="action?.iconColor"
                     [svgIcon]="action?.svgIcon"
                     [theme]="action?.theme"
                     [type]="action?.type"
                     [badge]="action?.badge"
                     [condition]="action?.condition ? action?.condition() : true"
                     [disabled]="action.disabled"
                     [tooltip]="action?.tooltip"
                     [text]="action?.text">
    </acc-form-button>
</div>
