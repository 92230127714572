import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {TranslateModule} from "@ngx-translate/core";

import {FiltersComponent} from './filters.component';
import { AngularMaterialModule } from "../../shared/base/angular-material.module";
import { FormModuleV2 } from "../../shared/components/form/form.module";

@NgModule({
    declarations: [
        FiltersComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        FormModuleV2,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        FiltersComponent
    ]
})
export class FiltersModule {
}
