export const USERS_TRANSLATE = {
    users: {
        messages: {
            delete: "Usuário removido com sucesso",
            recover: "Usuário ativado com sucesso",
            update: "Usuário atualizado com sucesso",
            create: "Usuário criado com sucesso",
            updatePassword: "Senha alterada com sucesso",
            photoAdded: "Foto adicionada com sucesso",
            photoRemoved: "Foto removida com sucesso",
        },
        read: {
            tabs: {
                data: "Dados pessoais",
                password: "Alterar senha",
                others: "Outros"
            }
        },
        list: {
            confirmDeleteDialog: {
                            title: "Remover usuário?",
                            body: 'Tem certeza que deseja remover o usuário "{{name}}"?',
                            actions: {
                                delete: "Sim, remover",
                                back: "Voltar",
                            },
                        },
            confirmInactivateDialog: {
                title: "Inativar usuário?",
                body: 'Tem certeza que deseja inativar o usuário "{{name}}"?',
                actions: {
                    inactivate: "Sim, inativar",
                    back: "Voltar",
                },
            },
            table: {
                flags: {
                    active: "Ativo",
                    notActive: "Inativo",
                },
                columns: {
                    id: "ID",
                    cpf: "CPF",
                    email: "E-mail",
                    name: "Nome",
                    createdAt: "Criado em",
                    lastSignInAt: "Último acesso",
                    active: "Situação",

                },
                tooltips: {
                    accept: "Aceito"
                }
            },
            localActions: {
                create: {
                    text: "Novo Usuário"
                },
                importCsv: {
                    text: "Importar usuário"
                }
            },
            filters: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome",
                        url: "nome",
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail",
                        url: "email",
                    },
                    active: {
                        label: "Situação",
                        placeholder: "Informe a situação",
                        url: "situacao",
                    }
                }
            }
        },
        save: {
            form: {
                fields: {
                    headquarterId: {
                        label: "Unidade",
                        placeholder: "Informe a unidade"
                    },
                    cpf: {
                        label: "CPF",
                        placeholder: "Informe o CPF"
                    },
                    cnpj: {
                        label: "CNPJ",
                        placeholder: "Informe o CNPJ"
                    },
                    corpName: {
                        label: "Razão social",
                        placeholder: "Informe a Razão social"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome"
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail"
                    },
                    currentPassword: {
                        label: "Senha atual",
                        placeholder: "Informe a senha atual"
                    },
                    password: {
                        label: "Nova senha",
                        placeholder: "Informe a nova senha",
                        hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    },
                    passwordConfirmation: {
                        label: "Confirmação de nova senha",
                        placeholder: "Informe a confirmação de nova senha",
                        hint: "A confirmação de senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    },
                    birthday: {
                        label: "Data de nascimento",
                        placeholder: "Informe a data de nascimento"
                    },
                    dontShowBirthday: {
                        label: "Não expor data de nascimento",
                        tooltip: "Ao selecionar esta opção, a sua data de nascimento não será exibida para os outros colaboradores"
                    },
                    address: {
                        label: "Endereço",
                        placeholder: "Informe o endereço"
                    },
                    phone: {
                        label: "Telefone da residência",
                        placeholder: "Informe o telefone da residência"
                    },
                    cellphone: {
                        label: "Telefone do celular",
                        placeholder: "Informe o telefone do celular"
                    },
                    photo: {
                        label: "Foto",
                        remove: "Remover foto",
                        add: "Adicionar foto",
                        change: "Alterar foto",
                        alternative: "Foto adicionada pelo usuário",
                        notFound: "Nenhuma foto adicionada"
                    },
                    admissionDate: {
                        label: "Data de admissão",
                        placeholder: "Informe a data de admissão"
                    },
                    phoneExtension: {
                        label: "Ramal",
                        placeholder: "Informe o ramal"
                    },
                    workTimeStart: {
                        label: "Horário de início do expediente",
                        placeholder: "Informe o horário de início do expediente",
                        hint: "Exemplo: 08:00h (início do turno)"
                    },
                    workTimeEnd: {
                        label: "Horário do final do expediente",
                        placeholder: "Informe o horário do final do expediente",
                        hint: "Exemplo: 17:00h (final do turno)"
                    },
                    lunchTimeStart: {
                        label: "Horário de início da refeição principal",
                        placeholder: "Informe o horário de início da refeição principal",
                        hint: "Exemplo: 12:00h (almoço ou jantar)"
                    },
                    lunchTimeEnd: {
                        label: "Horário do final da refeição principal",
                        placeholder: "Informe o horário do final da refeição principal",
                        hint: "Exemplo: 13:00h (almoço ou jantar)"
                    },
                    onVacation: {
                        label: "Em período de férias"
                    },
                    isAccountAdmin: {
                        label: "Administrador"
                    },
                    departmentId: {
                        label: "Departamento",
                        placeholder: "Informe o departamento"
                    },
                    shiftId: {
                        label: "Turno",
                        placeholder: "Informe o turno"
                    },
                },
            }
        },
        groups: {
            messages: {
                delete: "Grupo removido com sucesso",
                recover: "Grupo restaurado com sucesso",
                create: "Grupos adicionados com sucesso"
            },
            list: {
                label: "Grupos",
                table: {
                    columns: {
                        name: "Nome",
                        groupType: "Tipo"
                    }
                },
                actions: {
                    create: {
                        text: "Novos grupos"
                    }
                },
                listActions: {
                    delete: {
                        text: "Remover"
                    },
                    permissions: {
                        text: "Visualizar permissões"
                    }
                }
            },
            permissions: {
                title: "Permissões"
            },
            create: {
                form: {
                    title: "Novos grupos",
                    fields: {
                        groupsIds: {
                            label: "Grupos",
                            placeholder: "Informe os grupos"
                        }
                    },
                    actions: {
                        save: {
                            text: "Adicionar"
                        }
                    }
                }
            }
        },
        others: {
            form: {
                title: "Outros",
                subTitle: "Aqui você pode editar outros dados do usuário"
            }
        },
        forceModifyPassword: {
            form: {
                title: "Alteração de senha necessária",
                actions: {
                    save: {
                        text: "Alterar"
                    }
                }
            }
        },
        update: {
            form: {
                title: "Editar dados pessoais",
                actions: {
                    save: {
                        text: "Salvar"
                    }
                }
            }
        },
        create: {
            form: {
                title: "Criar usuário",
                actions: {
                    save: {
                        text: "Criar"
                    }
                }
            }
        },
        changePassword: {
            form: {
                title: "Alterar senha",
                actions: {
                    save: {
                        text: "Alterar"
                    }
                }
            }
        }
    },
};


