<acc-form-button *ngIf="secondaryAction?.condition && secondaryAction.condition()"
                [classes]="secondaryAction?.classes"
                [click]="secondaryAction?.click"
                [color]="secondaryAction?.color"
                [disabled]="secondaryAction?.disabled"
                [icon]="secondaryAction?.icon"
                [text]="secondaryAction?.text"
                [theme]="secondaryAction?.theme"
                [tooltip]="secondaryAction?.tooltip"
                [type]="secondaryAction?.type">
</acc-form-button>
<ng-content></ng-content>
<acc-form-button *ngIf="primaryAction?.condition && primaryAction.condition()"
                [classes]="primaryAction?.classes"
                [click]="primaryAction?.click"
                [color]="primaryAction?.color"
                [disabled]="primaryAction?.disabled"
                [icon]="primaryAction?.icon"
                [loading]="primaryAction?.loading"
                [text]="primaryAction?.text"
                [theme]="primaryAction?.theme"
                [tooltip]="primaryAction?.tooltip"
                [type]="primaryAction?.type">
</acc-form-button>
