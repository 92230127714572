import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from "@angular/material/autocomplete";

import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { AutocompleteScrollEvent } from "./models/autocomplete-scroll-event";

@Directive({
    selector: 'mat-autocomplete[accOptionsScroll]'
})
export class MatAutocompleteOptionsScrollDirective implements OnDestroy{
    @Input() thresholdPercent = 0.8;

    @Output() accOptionsScroll = new EventEmitter<AutocompleteScrollEvent>();
    onDestroy = new Subject();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    setTimeout(() => {
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement.addEventListener(
                            'scroll',
                            this.onScroll.bind(this)
                        );
                    }, 500);
                }),
                takeUntil(this.onDestroy)
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                takeUntil(this.onDestroy)
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        this.removeScrollEventListener();
    }

    onScroll(event: Event) {
        if (this.thresholdPercent === undefined) {
            this.accOptionsScroll.next({autoComplete: this.autoComplete, scrollEvent: event});
        } else {
            const scrollTop = (event.target as HTMLElement).scrollTop;
            const scrollHeight = (event.target as HTMLElement).scrollHeight;
            const elementHeight = (event.target as HTMLElement).clientHeight;
            const atBottom = scrollHeight === scrollTop + elementHeight;
            if (atBottom) {
                this.accOptionsScroll.next();
            }
        }
    }

    private removeScrollEventListener() {
        if (this.autoComplete?.panel) {
            this.autoComplete.panel.nativeElement.removeEventListener(
                'scroll',
                this.onScroll
            );
        }
    }
}
