<mat-form-field [appearance]="appearance"
                [class.datepicker--with-icon]="iconTooltip && tooltip"
                [floatLabel]="floatLabel"
                [formGroup]="form"
                [hintLabel]="hintLabel | translate"
                class="datepicker">
    <mat-label>{{ label | translate }}</mat-label>
    <input #dateInput
           (dateChange)="dateServ.onChange(dateInput, formatDate, field)"
           (dateInput)="dateServ.onChange(dateInput, formatDate, field)"
           [attr.aria-label]="label | translate"
           [formControlName]="name"
           [accFormDate]="textMaskConfig"
           [matDatepickerFilter]="filter"
           [matDatepicker]="datepicker"
           [name]="name"
           [placeholder]="placeholder | translate"
           [required]="required"
           aria-label=""
           autocomplete="off"
           matInput>
    <mat-datepicker-toggle [for]="datepicker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #datepicker
                    (monthSelected)="onMonthSelected($event, datepicker, date)"
                    (yearSelected)="onYearSelected($event, datepicker, date)"
                    [startView]="startView"></mat-datepicker>
    <mat-icon *ngIf="icon" aria-hidden="true" matSuffix>{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>

<mat-icon *ngIf="iconTooltip && tooltip" [matTooltip]="tooltip | translate" aria-hidden="true"
          class="datepicker__icon">{{iconTooltip}}</mat-icon>
