<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel" [formGroup]="form" class="select">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-select #select
                (focus)="onFocus()"
                [(value)]="value"
                [formControlName]="name"
                [multiple]="multiple"
                [placeholder]="placeholder | translate"
                [required]="required"
                [sortComparator]="sort">
        <mat-select-trigger><span>{{ displayValue() | translate }}</span></mat-select-trigger>
        <mat-option *ngIf="isLoading" disabled="true">
            <mat-spinner [diameter]="30"
                         [strokeWidth]="3"
                         class="select__loading"
                         mode="indeterminate">
            </mat-spinner>
        </mat-option>
        <mat-option *ngIf="nothing && !isLoading && !multiple"
                    [value]="nothingValue">{{ nothing | translate }}</mat-option>
        <mat-option *ngFor="let option of options" [value]="option[sendProperty]">
            {{ option[nameProperty] | translate }}
        </mat-option>
    </mat-select>
    <mat-icon *ngIf="icon" aria-hidden="true" matSuffix>{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
