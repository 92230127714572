import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { MDCRipple } from "@material/ripple";

@Component({
    selector: 'acc-form-fab',
    templateUrl: './form-fab.component.html',
    styleUrls: ['./form-fab.component.scss']
})
export class FormFabComponent implements AfterViewInit {
    @ViewChild('fab') fabElement: ElementRef;
    @Input() text = '';
    @Input() icon = '';
    @Input() href = '';

    private ripple: MDCRipple;

    ngAfterViewInit(): void {
        this.ripple = new MDCRipple(this.fabElement?.nativeElement);
    }
}
