import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { Params, Router } from "@angular/router";

import { Observable, Subject } from "rxjs";
import { take, tap } from "rxjs/operators";
import { Translate } from 'src/app/shared/helpers/translate';
import { Generic } from 'src/app/shared/models/generic';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';

import { BaseOption } from "../models/base-option";
import { environment } from "../../../environments/environment";
import { ObjectHelpers } from "../helpers/object-helpers";
import { RequestModifier } from "../models/request-modifier";

@Injectable({
    providedIn: 'root'
})
export class BaseService<T> {
    onUpdate = new Subject<Params>();
    onCreate = new Subject<Params>();
    onRecovering: Subject<boolean> = new Subject<boolean>();
    sendAsFormData = false;

    protected httpClient: HttpClient;
    protected snackBarService: SnackBarService;
    protected router: Router;
    protected activeProperty: string;
    protected identifierProperty: string;
    protected url: string;
    protected lastDeletedElement: Generic | null = null;

    protected requestModifiers: RequestModifier<T>[] = [];

    protected options: BaseOption = {
        objectName: '',
        translation: '',
        createNavigate: '',
        updateNavigate: ''
    };

    constructor(
        httpClient: HttpClient,
        router: Router,
        snackBarService: SnackBarService,
        @Inject(String) url: string,
        @Inject(Object) options: BaseOption,
        @Inject(String) activeProperty?: string,
        @Inject(String) identifierProperty?: string
    ) {
        this.httpClient = httpClient;
        this.router = router;
        this.snackBarService = snackBarService;
        this.url = `${environment.api}/${url}`;
        this.options = options;
        this.activeProperty = activeProperty || 'active';
        this.identifierProperty = identifierProperty || 'id';
    }

    get(httpParams: HttpParams = new HttpParams()): Observable<T[]> {
        return this.httpClient.get<T[]>(`${this.urlApi}`, {params: httpParams}).pipe(take(1));
    }

    single(identifier: number, httpParams: HttpParams = new HttpParams()): Observable<T> {
        return this.httpClient.get<T>(`${this.urlApi}/${identifier}`, {params: httpParams}).pipe(take(1));
    }
    generateReport(httpParams: HttpParams = new HttpParams()): Observable<{ file: string }> {
        return this.httpClient
            .get<{ file: string }>(`${this.urlApi}.xlsx`, {params: httpParams})
            .pipe(take(1));
    }


    // create(form: FormGroup): Observable<T> {
    //     return this.httpClient
    //         .post<T>(`${this.urlApi}`, {[this.options.objectName]: {...form.getRawValue()}})
    //         .pipe(
    //             take(1),
    //             tap({
    //                 next: resp => {
    //                     this.onCreate.next(resp);
    //                     this.finalizeRequest(
    //                         `${this.options.translation}.messages.create`,
    //                         this.options.createNavigate as string
    //                     );
    //                 }
    //             })
    //         );
    // }

    create(form: FormGroup): Observable<T> {
        const dataToSend = this.setFieldsBeforeCreate({...form.getRawValue()});

        return this.httpClient
            .post<T>(`${this.urlApi}`, dataToSend)
            .pipe(
                take(1),
                tap({
                    next: (resp) => {
                        this.onCreate.next(resp);
                        this.finalizeRequest(
                            `${this.options.translation}.messages.create`,
                            this.options.createNavigate as string
                        );
                    },
                })
            );
    }

    setFieldsBeforeCreate(rawValue: Generic): Generic {
        const valueToSend = {
            [this.options.objectName]: this.applyModifiers(rawValue)
        };

        if(this.sendAsFormData)
            return Auxiliary.createFormDataByObjects(valueToSend);

        return valueToSend;
    }

    setFieldsBeforeUpdate(rawValue: Generic): Generic{
        const valueToSend = {
            [this.options.objectName]: this.applyModifiers(rawValue)
        };

        if(this.sendAsFormData)
            return Auxiliary.createFormDataByObjects(valueToSend);

        return valueToSend;
    }



    applyModifiers(rawValue: Generic){
        if(!this.requestModifiers?.length) return rawValue;

        return ObjectHelpers.modifyObject(rawValue, this.requestModifiers);
    }

    update(identifier: number, form: FormGroup): Observable<T> {
        const dataToSend = this.setFieldsBeforeUpdate({...form.getRawValue()});

        return this.httpClient
            .patch<T>(`${this.urlApi}/${identifier}`, dataToSend)
            .pipe(
                take(1),
                tap({
                    next: (response) => {
                        this.onUpdate.next(response);
                        this.finalizeRequest(`${this.options.translation}.messages.update`, this.options.updateNavigate as string);
                    },
                })
            );
    }

    delete(element: Generic, params?: Params): Observable<T> {
        return this.httpClient
            .delete<T>(`${this.urlApi}/${element[this.identifierProperty]}`, {})
            .pipe(
                take(1),
                tap({next: () => this.finalizeDelete(element, params)})
            );
    }

    recover(element: Generic): Observable<T> {
        return this.httpClient
            .put<T>(`${this.urlApi}/${element[this.identifierProperty]}/recover`, {})
            .pipe(
                take(1),
                tap({next: () => this.finalizeRecover(element)})
            );
    }

    autocomplete(params: HttpParams = new HttpParams()): Observable<any> | Observable<any[]> {
        params = params.append('active', 'true');

        return this.httpClient.get<any>(`${this.urlApi}/autocomplete`, {params}).pipe(take(1));
    }

    protected finalizeRequest(successMessage: string, nextUrl: string): void {
        this.snackBarService.create(successMessage, true);


        if (nextUrl) this.router.navigateByUrl(Translate.value(nextUrl));

    }

    protected finalizeDelete(element: Generic, params?: Params): void {
        this.changeActiveProperty(element);

        this.snackBarService.create(`${this.options.translation}.messages.delete`, true, {
            duration: SnackBarService.longTime,
            data: {
                action: {
                    text: "snackBar.actions.restore.text",
                    click: () => this.recover.call(this, this.lastDeletedElement as Generic, params).pipe(take(1)).subscribe(),
                    condition: () => true,
                }
            }
        });
    }

    protected finalizeRecover(element: Generic): void {
        this.changeActiveProperty(element, true);
        this.onRecovering.next(true);

        this.snackBarService.create(`${this.options.translation}.messages.recover`, true, {
            duration: SnackBarService.time,
            data: {action: {condition: () => false}}
        });
    }

    protected changeActiveProperty(element: Generic, isRecover?: boolean): void {
        element[this.activeProperty] = !element[this.activeProperty];
        this.lastDeletedElement = isRecover ? null : element;
    }

    protected get urlApi(): string {
        return this.url;
    }
}
