import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog/dialog-ref";
import { Router, RoutesRecognized } from "@angular/router";

import { Subscription } from "rxjs";

import { Dialog } from "./models/dialog";
import { Auxiliary } from "../../helpers/auxiliary";

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    dialogAssets: Dialog = {} as Dialog;

    private dialogRefence: MatDialogRef<any> = {} as MatDialogRef<any>;
    private afterClose$: Subscription;
    private readonly config = {
        ariaLabel: 'dialog.ariaLabel',
        autoFocus: false,
        closeOnNavigation: true,
        hasBackdrop: true,
        disableClose: false,
        restoreFocus: true,
        role: 'dialog', // or 'alertdialog'
        maxWidth: 1200
    };

    constructor(public materialAngularDialog: MatDialog, private router: Router){
        this.watchChangeRoute();
    }

    open<D=any>(dialog: Dialog<D>): void{
        if(this.isThereDialogOpened()) return;

        this.dialogAssets = dialog;
        this.dialogAssets.configurations = Auxiliary.assignAllObjects({}, this.config, this.dialogAssets.configurations);
        this.dialogRefence = this.materialAngularDialog.open(this.dialogAssets.component, this.dialogAssets.configurations);
        this.afterClose$ = this.dialogRefence.afterClosed().subscribe(() => this.close());
    }

    close(): void{
        this.dialogAssets = {} as Dialog;
        this.dialogRefence?.close?.();

        Auxiliary.unsubscribeAll([this.afterClose$]);
    }

    isThereDialogOpened(): boolean{
        return Auxiliary.objectHasKeysLength(this.dialogAssets);
    }

    private watchChangeRoute(): void{
        this.router.events.subscribe(routerEvent =>
            this.dialogAssets?.configurations?.closeOnNavigation
            &&
            routerEvent instanceof RoutesRecognized
            &&
            !Auxiliary.isEqualRoute([routerEvent.url, this.router.url], { digitToSlice: '?' })
            &&
            this.close()
        );
    }
}
