import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { FormGroup } from "@angular/forms";

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Translate } from 'src/app/shared/helpers/translate';
import { Generic } from 'src/app/shared/models/generic';

import { Filter } from '../../shared/models/filter';
import { FilterField } from "../../shared/models/filter-field";

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    private filtersSubject = new Subject<Filter>();
    private toggleSubject = new Subject<any>();
    private closeSubject = new Subject<any>();
    private activesBehaviorSubject = new BehaviorSubject<number>(0);

    constructor(private activatedRoute: ActivatedRoute) {
    }

    watchFilters(): Observable<Filter> {
        return this.filtersSubject.asObservable();
    }

    sendFilters(filters: Filter): void {
        this.filtersSubject.next(filters);
    }

    onToggle(): Observable<any> {
        return this.toggleSubject.asObservable();
    }

    open(): void {
        this.toggleSubject.next();
    }

    onClose(): Observable<any> {
        return this.closeSubject.asObservable();
    }

    close(): void {
        this.closeSubject.next();
    }

    watchActives(): Observable<number> {
        return this.activesBehaviorSubject.asObservable();
    }

    sendActives(actives: number): void {
        this.activesBehaviorSubject.next(actives);
    }

    removeFiltersFields(parameters: Params, form: FormGroup): Params {
        const newParameters: Params = {};
        const fieldKeys = Object.keys(form.getRawValue());

        for (const parameter in parameters)
            if (parameters.hasOwnProperty(parameter) && !fieldKeys.includes(parameter))
                newParameters[parameter] = parameters[parameter];

        return newParameters;
    }

    getFiltersOnURL(fields: FilterField[]): Params {
        const filters: Params = {};
        const filtersUrlNames: Generic[] = [];

        fields.forEach(field => {
            if (field.startUrlParameter) filtersUrlNames.push({
                name: field.startName,
                urlParameter: field.startUrlParameter
            });
            if (field.endUrlParameter) filtersUrlNames.push({name: field.endName, urlParameter: field.endUrlParameter});
            if (field.urlParameter) filtersUrlNames.push({name: field.name, urlParameter: field.urlParameter});
        });

        this.activatedRoute
            .queryParams
            .subscribe(parameters =>
                filtersUrlNames.forEach(filter => {
                    const value = parameters[Translate.value(filter.urlParameter)];

                    if (value) filters[filter.name] = value;
                })
            );

        return filters;
    }
}
