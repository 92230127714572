import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from "@angular/material/select";
import { Params } from "@angular/router";
import { MatOption } from "@angular/material/core";

import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { finalize, map, take } from "rxjs/operators";

import { Generic } from 'src/app/shared/models/generic';
import { FormService } from "../form.service";
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';

@Component({
    selector: 'acc-form-select',
    templateUrl: './form-select.component.html',
    styleUrls: ['./form-select.component.scss'],
    preserveWhitespaces: false
})
export class FormSelectComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('select') select: MatSelect;
	@Output() externalOptions = new EventEmitter<{ options: any[]; filteredOptions?: Observable<any[]> }>();
    @Input() label = '';
    @Input() type = 'select';
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() placeholder = '';
    @Input() icon = '';
    @Input() hint = '';
    @Input() hintLabel = '';
    @Input() required: boolean;
    @Input() returnName = '';
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() lowerCaseAfterFirst = false;
    @Input() service: any;
    @Input() isNotAsynchronous = false;
    @Input() isLoading = false;
    @Input() multiple = false;
    @Input() name = '';
    @Input() loadBefore = true;
    @Input() form: FormGroup;
    @Input() autofocus = false;
    @Input() cleanCache = false;
    @Input() sortDirection = 'asc';
    @Input() perPage = 25;
    @Input() paramsSubject: Subject<Params> | BehaviorSubject<Params>;
    @Input() params: Params = {};
    method = 'get';
    lastSeparator = '';
    nameProperty = 'name';
    sendProperty = 'id';
    sortProperty = this.nameProperty;
    options: Generic[] = [];
    nothing = '';
    nothingValue = '';
    value: Generic[] | Generic = this.multiple ? [] : {};
    field: FormControl;
    formsHelper = FormService;
    firstTime = true;
    private subscriptions: Subscription[] = [];

    constructor(private translateService: TranslateService) {
    }

    @Input('method')
    set setGet(method: string) {
        if (method) this.method = method;
    }

    @Input('options')
    set setOptions(options: Generic[]) {
        this.options = options && options.length ? options : [];
    }

    @Input('nothing')
    set setNothing(nothing: string) {
        this.nothing = nothing || 'form.select.nothing';
    }

    @Input('nameProperty')
    set setNameProperty(nameProperty: string) {
        if (nameProperty) this.nameProperty = nameProperty;
    }

    @Input('sendProperty')
    set setSendProperty(sendProperty: string) {
        if (sendProperty) this.sendProperty = sendProperty;
    }

    @Input('sortProperty')
    set setSortProperty(sortProperty: string) {
        if (sortProperty) this.sortProperty = sortProperty;
    }

    ngOnInit(): void {
        this.field = this.formsHelper.getField(this.form as FormGroup, this.name);
        this.required = Auxiliary.isBoolean(this.required) ? this.required : this.formsHelper.getRequired(this.field);
        this.translateService.get('form.select.lastSeparator').subscribe(lastSeparator => this.lastSeparator = lastSeparator);

        this.watchParamsSubject();
        this.watchChanges();
        this.setInitialValue();

        if (this.loadBefore && this.isAsynchronous()) this.get();
    }

    ngAfterViewInit(): void {
        if (this.autofocus) setTimeout(() => this.select?.open(), 500);
    }

    get(): void {
        this.isLoading = true;

        this.subscriptions.push(
            this.service?.[this.method](
                Auxiliary.createHttpParams(
                    Auxiliary.onlyValidParameters({
                        perPage: this.perPage,
                        sortDirection: this.sortDirection,
                        sortProperty: this.sortProperty,
                        ...this.params
                    })
                )
            )
                .pipe(
                    finalize(() => this.isLoading = false),
                    take(1),
                    map((response: Generic) => this.returnName ? response[this.returnName] : response)
                )
                .subscribe((options: Generic[]) => {
					this.options = options;
					this.sendExternalOptions();
				})
        );
    }

    onFocus(): void {
        if (this.cleanCache && !this.isLoading && this.firstTime) {
            this.get();
            this.firstTime = false;
        } else if (this.isAsynchronous() && !this.isLoading) {
            this.get();
        }
    }

    sort(a: MatOption, b: MatOption) {
        return 1;
    }

    displayValue(): string {
        const value = this.field?.value;
        let display = '';

        switch (true) {
            case Auxiliary.isNumber(value) || Auxiliary.isBoolean(value):
                display = this.options.length ? this.options.filter(item => item[this.sendProperty] === value)[0][this.nameProperty] : '';
                break;
            case Auxiliary.isArray(value):
                display = this.options.length
                    ?
                    Auxiliary.listToString(
                        value.map((id: number) => this.options.find(item => item[this.sendProperty] === id)),
                        this.nameProperty,
                        ', ',
                        this.lastSeparator,
                        true
                    )
                    :
                    '';
                break;
            case value === this.nothingValue:
                display = this.nothing;
                break;
        }

        return this.lowerCaseAfterFirst ? Auxiliary.capitalizeFirstLetter(display) : display;
    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    private setInitialValue(value = this.field?.value): void {
        if (this.nothing && (Auxiliary.isNull(value) || Auxiliary.isUndefined(value)))
            this.field?.setValue(
                this.nothingValue,
                {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true,
                    emitViewToModelChange: false,
                }
            );
    }

    private isAsynchronous(): boolean {
        return !this.options.length && !this.isNotAsynchronous;
    }

    private watchParamsSubject(): void {
        if (this.paramsSubject)
            this.subscriptions.push(this.paramsSubject.asObservable().subscribe(params => Object.assign(this.params, params)));
    }

    private watchChanges(): void {
        this.subscriptions.push(
            this.field.valueChanges.subscribe(value => {
                if (value && value.length === 0) value = null;

                this.setInitialValue(value);
            })
        );
    }

	private sendExternalOptions(): void {
        this.externalOptions.next({
            options: this.options || []
        });
    }
}
