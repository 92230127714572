import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from "rxjs";
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';
import { FormButton } from 'src/app/shared/components/form/button/models/form-button';
import { Generic } from 'src/app/shared/models/generic';

import { LocalActionsService } from './local-actions.service';
import { LocalAction } from '../../shared/models/local-action';

@Component({
    selector: 'acc-local-actions',
    templateUrl: './local-actions.component.html',
    styleUrls: ['./local-actions.component.scss']
})
export class LocalActionsComponent implements LocalAction, OnInit, OnDestroy {
    @Input() where = '';
    localActions: FormButton[] = [];
    isTab = false;

    private subscriptions: Subscription[] = [];

    constructor(private actionsService: LocalActionsService, private changeDetectorReference: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.actionsService
                .watchActions()
                .subscribe(actions => {
                    this.setIsTab(actions);
                    this.setLocalActions(actions);
                    this.sendCreateAction();
                    this.changeDetectorReference.detectChanges();
                })
        );
    }

    areThereLocalActions(): boolean {
        return !!this.localActions.length;
    }

    setIsTab(actions: LocalAction | Generic): void {
        this.isTab = !!actions?.isTab;
    }

    isBreacrumb(): boolean {
        return this.where === 'breadcrumb' && !this.isTab;
    }

    isTabs(): boolean {
        return this.where === 'tabs' && this.isTab;
    }

    setLocalActions(actions: LocalAction | Generic): void {
        if (this.isTabs() || this.isBreacrumb()) {
            if (actions?.localActions) this.localActions = actions.localActions;
            else this.localActions = [];
        }
    }

    sendCreateAction(): void {
        if (!this.isBreacrumb()) return;

        let indexAction = -1;

        this.localActions.forEach((action: FormButton, index: number) => action.isNew ? indexAction = index : null);

        if (indexAction >= 0) this.actionsService.sendCreateAction(this.localActions.splice(indexAction, 1)[0]);
        else this.actionsService.sendCreateAction({});
    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }
}
