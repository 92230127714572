export const HEADQUARTERS_TRANSLATE = {
	headquarters: {
		messages: {
            inactive: "Unidade inativada com sucesso",
			delete: "Unidade deletada com sucesso",
			update: "Unidade atualizada com sucesso",
            recover: "Unidade ativada com sucesso",
            create: "Unidade criada com sucesso"

		},
		list: {
			confirmInactivateDialog: {
				title: "Inativar uniadade?",
				body: 'Tem certeza que deseja inativar a unidade "{{name}}"?',
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover a unidade?",
			},
			table: {
				columns: {
                    name: "Nome",
                    account: "Conta",
                    id: "ID",
                    stateId: "Estado",
                    cityId: "Cidade",
                    cnpj: "CNPJ"
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
                    name: {
                    	label: "Nome",
                    	placeholder: "Informe aqui o nome",
                    	url: "name",
                    },
                    accountId: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta",
                        url: "account",
                    },
                    cnpj: {
                        label: "CNPJ",
                        placeholder: "Informe aqui o CNPJ",
                        url: "cnpj",
                    },
                    city: {
                        label: "Cidade",
                        placeholder: "Informe aqui a cidade",
                        url: "city",
                    },
                    state: {
                        label: "Estado",
                        placeholder: "Informe aqui o estado",
                        url: "state",
                    },
                    headquartersSituations: {
                        label: "Situação",
                        placeholder: "Informe aqui a situação",
                        url: "situations",
                    }
                },
			},
		},

	    create: {
			form: {
                title: "Nova unidade",

				actions: {
					save: {
						text: "Salvar"
					},
                    add:{
                        text: "Adicionar"
                    }
				}
			}
		},
        read: {
            tabs: {
                data: "Dados",
                // users: "Usuários"
            }
        },
        update: {
            form: {
                title: "Editar unidade",
                actions: {
                    save: {
                        text: "Salvar"
                    }
                }
            }
        },
		save: {
			form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui a unidade",
                    },
                    account: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta",
                    },
                    environmentType: {
                        label: "Ambiente",
                        placeholder: "Informe aqui o ambiente",
                    },
            },
                actions: {
                    save: {
                        text: "Salvar"
                    },
                  },
				},
			}

		},

};


