import { Injectable, Inject  } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";

import { LOAD_ENV } from "../environments/environment";


@Injectable({
	providedIn: 'root'
})


export class EnvService {
	isBrowser: boolean;

	constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: number) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	public async load(){
		if(!this.isBrowser) return;

		await LOAD_ENV();
	}
}
