export const CORPORATE_TV_VERSIONS_TRANSLATE = {
	corporateTvVersions: {
		messages: {
            inactive: "Versão tv corporativa inativada com sucesso",
			delete: "Versão tv corporativa inativada com sucesso",
			update: "Versão tv corporativa atualizada com sucesso",
            recover: "Versão tv corporativa ativada com sucesso",
            create: "Versão tv corporativa criada com sucesso"

		},
		list: {
			confirmInactivateDialog: {
				title: "Inativar versão tv corporativa?",
				body: 'Tem certeza que deseja inativar  a versão tv corporativa "{{name}}"?',
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover versão tv corporativa ?",
			},
			table: {
				columns: {
					id: "ID",
                    name: "Nome",
                    androidVersion: "Versão android"
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
                    companyName: {
						label: "Conta",
						placeholder: "Informe aqui a conta",
						url: "account",
					},
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome",
                        url: "name",
                    },
                    user: {
                        label: "Usuário",
                        placeholder: "Informe aqui o usuário",
                        url: "user",
                    },
                    id: {
                        label: "ID",
                        placeholder: "Informe aqui o ID",
                        url: "id",
                    },
				},
			},
		},
	    create: {
			form: {
                fields: {
                    companyName: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome"
                    },
                    androidVersion: {
                        label: "Versão android",
                        placeholder: "Informe aqui a versão do android"
                    },
                    logo: {
                        label: "APK",
                        remove: "Remover",
                        add: "Adicionar",
                        change: "Alterar",
                        alternative: "Adicionado pelo usuário com sucesso",
                        notFound: "Nenhum arquivo adicionado ainda"
                    },
                    accountIds: {
                        label: "Contas",
                        placeholder: "Informe aqui a conta"
                    },

                },
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		update: {
			form: {
                fields: {
                    companyName: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome"
                    },
                    androidVersion: {
                        label: "Versão android",
                        placeholder: "Informe aqui a versão do android"
                    },
                    logo: {
                        label: "APK",
                        remove: "Remover",
                        add: "Adicionar",
                        change: "Alterar",
                        alternative: "Adicionado pelo usuário com sucesso",
                        notFound: "Nenhum arquivo adicionado ainda"
                    }
                },
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		read: {
			tabs: {
				data: "Dados",
				// users: "Usuários"
			}
		},
		save: {
			form: {
				fields: {
					customUuid: {
						label: "UUID",
						placeholder: "Informe aqui o uuid"
					},
					companyName: {
						label: "Conta",
						placeholder: "Informe aqui a conta"
					},
				},
			}
		},
	},

};


