import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Params, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

import { Observable, Subject } from "rxjs";
import { take, tap } from "rxjs/operators";

import { User } from "../../shared/models/user";
import { BaseService } from "../../shared/base/base.service";
import { environment } from "../../../environments/environment";
import { SnackBarService } from "../../shared/components/snack-bar/snack-bar.service";
import { Account } from "../../shared/models/account";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { Translate } from "../../shared/helpers/translate";

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseService<User> {
    static user: User | null;

    private userChangedSuject = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        router: Router,
        snackBarService: SnackBarService,
    ) {
        super(http, router, snackBarService, `users`, {
                objectName: 'user',
                translation: 'users',
                createNavigate: 'routes.users.list',
                updateNavigate: 'routes.users.list'
            }
        );
    }

    // forceModifyPassword(id: number, form: FormGroup): Observable<User> {
    //     return this.httpClient
    //         .patch<User>('', {[this.options.objectName]: form.getRawValue()})
    //         .pipe(
    //             take(1),
    //             tap({
    //                 next: response =>
    //                     this.concludedUpdate(`${this.options.translation}.messages.updatePassword`, response)
    //             })
    //         );
    // }

    single(id: number, params = new HttpParams()): Observable<User> {
        return this.httpClient.get<User>(`${this.urlApi}/${id}`, {params}).pipe(take(1));
    }

    autocomplete(params: HttpParams = new HttpParams()): Observable<User[]> {
        params = params.append('active', 'true');

        return this.httpClient.get<User[]>(`${this.url}/autocomplete`, {params}).pipe(take(1));
    }

    finalizeDelete(element: User): void {
        this.situationAction(element);
    }

    finalizeRecover(element: User): void {
        this.situationAction(element, true);
    }

    images(identifier: number | null, form: FormGroup): Observable<Account> {
        let message = `${this.options.translation}.messages.`;
        const formValues = form.getRawValue();

        message += formValues.photo ? 'photoAdded' : 'photoRemoved';

        return this.httpClient
            .patch<Account>(
                `${this.urlApi}/images`,
                Auxiliary.createFormDataByObjects({[this.options.objectName]: form.getRawValue()})
            )
            .pipe(
                take(1),
                tap({next: (response) => this.concludedUpdate(message, response)})
            );
    }
    get isAtMyProfile(): boolean {
        return Auxiliary.isEqualRoute(
            [Translate.value('routes.myProfile.path'), Auxiliary.removeUrlParameters(this.router.url)]
        );
    }

    setUser(user: User | null, fromSwitchUser = false): void {
        UsersService.user = user;
        this.userChangedSuject.next(fromSwitchUser);
    }

    get getUser(): User | null {
        return UsersService.user;
    }

    get isAdmin(): boolean {
        return !!UsersService.user?.isAdmin;
    }

    get isAccountAdmin(): boolean {
        return !!UsersService.user?.isAccountAdmin;
    }

    switchToUser(userId: number): Observable<HttpResponse<any>> {
        return this.httpClient.post<User>(`${environment.api}/switch_to_user`, {userId}, {observe: 'response'});
    }

    watchUserChange(): Observable<boolean> {
        return this.userChangedSuject.asObservable();
    }

    accept(): Observable<Params> {
        return this.httpClient
            .patch<User>(
                `${environment.api}/current_user/accept_use_term`,
                {user: {}})
            .pipe(take(1));
    }

    private situationAction(element: User, isRecover?: boolean): void {
        this.changeActiveProperty(element, isRecover);

        this.snackBarService.create(
            isRecover ? `${this.options.translation}.messages.recover` : `${this.options.translation}.messages.delete`,
            true,
            {
                duration: isRecover ? SnackBarService.time : SnackBarService.longTime,
                data: {action: {condition: () => false}}
            }
        );
    }

    private concludedUpdate(message: string, response: Params): void {
        const userUpdated = response.user as User;

        if (this.getUser?.id === userUpdated.id) this.setUser(userUpdated);

        this.snackBarService.create(message, true);
    }
}
