import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDateFormats } from "@angular/material/core/datetime/date-formats";
import { MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';

import { Subscription } from "rxjs";

import { FormService } from "../form.service";
import { Moment } from 'src/app/shared/helpers/moment';
import { Generic } from 'src/app/shared/models/generic';

import { FormDateService } from "../form-date.service";
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';

@Component({
    selector: 'acc-form-date-range',
    templateUrl: './form-date-range.component.html',
    styleUrls: ['./form-date-range.component.scss'],
    preserveWhitespaces: false,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        }
    ]
})
export class FormDateRangeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatStartDate) matStartDate: MatStartDate<any>;
    @ViewChild(MatEndDate) matEndDate: MatEndDate<any>;
    @ViewChild('startInput') startInput: ElementRef;
    @ViewChild('endInput') endInput: ElementRef;
    @Output() onYearSelect = new EventEmitter<{
        normalizedYear: Moment;
        dateRange: MatDateRangePicker<any>;
        date: FormControl;
    }>();
    @Output() onMonthSelect = new EventEmitter<{
        normalizedMonth: Moment;
        dateRange: MatDateRangePicker<any>;
        date: FormControl;
    }>();
    @Input() label = '';
    @Input() type = '';
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() icon = '';
    @Input() hint = '';
    @Input() hintLabel = '';
    @Input() required: boolean;
    @Input() separator = 'form.dateRange.separator';
    @Input() initPlaceholder = 'form.dateRange.initPlaceholder';
    @Input() finalPlaceholder = 'form.dateRange.finalPlaceholder';
    @Input() startName = '';
    @Input() closeOnYearSelect = false;
    @Input() closeOnMonthSelect = false;
    @Input() endName = '';
    @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() form: FormGroup;
    formatDate = 'DD/MM/YYYY';
    textMaskConfig: Generic = {mask: []};
    startField: FormControl;
    endField: FormControl;
    formsHelper = FormService;
    date = new FormControl(Moment.moment());
    filter: any;
    subscriptions: Subscription[] = [];

    constructor(@Inject(MAT_DATE_FORMATS) public dateFormats: MatDateFormats, public dateServ: FormDateService) {
    }

    @Input('formatDate')
    set setFormatDate(format: string) {
        if (format) this.formatDate = format;
    }

    @Input('filter')
    set setFilter(filter: any) {
        this.filter = filter || (() => true);
    }

    ngOnInit(): void {
        this.startField = this.formsHelper.getField(this.form as FormGroup, this.startName);
        this.endField = this.formsHelper.getField(this.form as FormGroup, this.endName);
        this.required = this.formsHelper.getRequired(this.startField) || this.formsHelper.getRequired(this.endField);
        this.dateFormats.display.dateInput = this.formatDate;
        this.dateFormats.parse.dateInput = this.formatDate;
        this.textMaskConfig.mask = this.dateServ.setMask(this.formatDate);

        this.watchFieldsChanges();
    }

    ngAfterViewInit(): void {
        this.startField?.markAsPristine();
        this.endField?.markAsPristine();

        this.subscriptions.push(
            this.dateServ.watchInputChange(this.startInput as ElementRef, this.matStartDate as MatStartDate<any>),
            this.dateServ.watchInputChange(this.endInput as ElementRef, this.matEndDate as MatEndDate<any>)
        );
    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    onYearSelected(normalizedYear: Moment, dateRange: MatDateRangePicker<any>, date: FormControl) {
        //@ts-ignore
        this.dateServ.chosenYearHandler(normalizedYear, date);

        this.onYearSelect.emit({
            normalizedYear,
            dateRange,
            date
        });
    }

    onMonthSelected(normalizedMonth: Moment, dateRange: MatDateRangePicker<any>, date: FormControl) {
        //@ts-ignore
        this.dateServ.chosenMonthHandler(normalizedMonth, date);

        this.onMonthSelect.emit({
            normalizedMonth,
            dateRange,
            date
        });
    }

    skipToNextInput(): void {
        if (this.startInput?.nativeElement.value?.length === this.formatDate.length) this.endInput?.nativeElement.focus();
    }

    private watchFieldsChanges(): void {
        this.subscriptions.push(
            ...[this.startField, this.endField].map(field =>
                field.valueChanges.subscribe(value => {
                    if (Auxiliary.isString(value) && value.length >= 13)
                        field.setValue(new Date(value), {emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false});
                })
            )
        );
    }
}
