import { Injectable } from '@angular/core';
import { QueryParamsHandling, Router, RoutesRecognized } from '@angular/router';

import { Observable, of, Subject } from "rxjs";
import { Translate } from 'src/app/shared/helpers/translate';

import { Menu } from "../../shared/models/menu";

const list: Menu[] = [
    {
        icon: 'account_box',
        name: 'menu.items.customers.name',
        group: 'menu.group.accessModules',
        path: 'routes.accounts.list',
        isAdmin: true,
    },
    {
        icon: 'badge',
        name: 'menu.items.nicknames.name',
        group: 'menu.group.accessModules',
        path: 'routes.nicknames.list',
        isAdmin: true,
    },
    {
        icon: 'business',
        name: 'menu.items.headquarters.name',
        group: 'menu.group.accessModules',
        path: 'routes.headquarters.list',
        isAdmin: true,
    },
    {
        icon: 'account_tree',
        name: 'menu.items.mobileVersions.name',
        group: 'menu.group.mobiles',
        path: 'routes.mobileVersions.list',
        isAdmin: true,
    },
    {
        icon: 'fingerprint',
        name: 'menu.items.repPAccesses.name',
        group: 'menu.group.repP',
        path: 'routes.repPAccesses.list',
        isAdmin: true,
    },
    {
        icon: 'tv',
        name: 'menu.items.androidSticks.name',
        group: 'menu.group.corporateTv',
        path: 'routes.androidSticks.list',
        isAdmin: true,
    },
    {
        icon: 'account_tree',
        name: 'menu.items.corporateTvVersions.name',
        group: 'menu.group.corporateTv',
        path: 'routes.corporateTvVersions.list',
        isAdmin: true,
    },
    {
        icon: 'people_outline',
        name: 'menu.items.users.name',
        group: 'menu.group.users',
        path: 'routes.users.list',
        isAdmin: true,
    },


];

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    reorderMenu = new Subject<boolean>();
    toggle = new Subject<boolean>();
    close = new Subject<boolean>();

    private menuList: Menu[] = this.translateMenuList(list);
    private activatedItem: Menu = {} as Menu;
    private menuLoading = false;
    private limit = 1200;

    constructor(private router: Router) {
        this.setActivatedItem(this.findActivatedItem(this.router.url));

        this.router.events.subscribe(routerEvent => {
            if (routerEvent instanceof RoutesRecognized) this.setActivatedItem(this.findActivatedItem(routerEvent.urlAfterRedirects));
        });
    }

    translateMenuList(menuList: Menu[]): Menu[] {
        return menuList.map(item => ({...item, path: Translate.value(item.path)}));
    }

    setActivatedItem(item: Menu): void {
        if (item) this.activatedItem = item;
    }

    // findItem(first: boolean = false): Menu {
    //     const firstItem: null | Menu = first || location.pathname === '/' ? this.menuList[0] : null;
    //     const item: Menu = this.menuList.filter((route: Menu) => location.pathname.includes(route.path))[0];
    //
    //     return firstItem || item;
    // }

    chooseHandling(path: string): Observable<QueryParamsHandling> {
        return of(this.router.url.replace(/\?.+/, '') === path ? 'preserve' : '');
    }

    isBiggerThanLimit(width: number = window.innerWidth): boolean {
        return width < this.limit;
    }

    // get firstConfigItem(): Menu {
    //     return this.list.find(item => item?.isAtConfigurations) as Menu;
    // }

    get list(): Menu[] {
        return this.menuList;
    }

    set list(menuList: Menu[]) {
        this.menuList = [...menuList, ...list];
    }

    get loading(): boolean {
        return this.menuLoading;
    }

    setLoading(loading: boolean, timeout = 0) {
        setTimeout(() => this.menuLoading = loading, timeout);
    }

    private findActivatedItem(url: string): Menu {
        return this.menuList.filter((item: Menu) => url.includes(item.path))[0];
    }
}
