export const ANDROID_STICKS_TRANSLATE = {
	androidSticks: {
		messages: {
            inactive: "Aparelho de TV inativado com sucesso",
			delete: "Aparelho de TV  inativado com sucesso",
			update: "Aparelho de TV  de uso removido com sucesso",
            recover: "Aparelho de TV reativado com sucesso",
            create: "Aparelho de TV criado com sucesso"

		},
		list: {
			confirmInactivateDialog: {
				title: "Inativar Aparelho de TV?",
				body: 'Tem certeza que deseja inativar o aparelho de TV da conta "{{name}}"?',
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover Aparelho de TV?",
			},
			table: {
				columns: {
					id: "ID",
                    customUuid: "UUID",
                    companyName: "Conta",
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
                    customUuid: {
						label: "UUID",
						placeholder: "Informe aqui o uuid",
						url: "uuid",
					},
                    companyName: {
						label: "Conta",
						placeholder: "Informe aqui a conta",
						url: "conta",
					},
                    active: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta",
                        url: "conta",
                    },
				},
			},
		},
	    create: {
			form: {
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		update: {
			form: {
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		read: {
			tabs: {
				data: "Dados",
				// users: "Usuários"
			}
		},
		save: {
			form: {
				// subTitle: "Crie o seu termo de forma dinâmica.",
				fields: {
					customUuid: {
						label: "UUID",
						placeholder: "Informe aqui o uuid"
					},
					companyName: {
						label: "Conta",
						placeholder: "Informe aqui a conta"
					},
				},
			}
		},
	},

};


