import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalActionsComponent } from './local-actions.component';
import { FormModuleV2 } from 'src/app/shared/components/form/form.module';

@NgModule({
    declarations: [
        LocalActionsComponent
    ],
    imports: [
        CommonModule,
        FormModuleV2,
    ],
    exports: [
        LocalActionsComponent
    ]
})
export class LocalActionsModule {
}
