import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from "@ngx-translate/core";

import { MenuComponent } from './menu.component';
import { MenuBreadcrumbComponent } from './breadcrumb/menu-breadcrumb.component';
import { MenuToolbarComponent } from './toolbar/menu-toolbar.component';
import { LocalActionsModule } from '../local-actions/local-actions.module';
import { FiltersModule } from '../filters/filters.module';
import { MenuOrderPipe } from "../../shared/pipes/menu-order/menu-order.pipe";
import { FormModuleV2 } from "../../shared/components/form/form.module";
import { ProgressSpinnerModule } from "../../shared/components/progress-spinner/progress-spinner.module";
import { NoResultsModule } from "../../shared/components/no-results/no-results.module";
import { PipesModule } from "../../shared/pipes/pipes.module";
import { AngularMaterialModule } from "../../shared/base/angular-material.module";
import { DirectivesModule } from "../../shared/directives/directives.module";
import { LinkModule } from "../../shared/components/link/link.module";
import { AvatarModule } from "../../shared/components/avatar/avatar.module";
import { CopyrightModule } from "../../shared/components/copyright/copyright.module";
import { VarModule } from "../../shared/directives/var/var.module";

@NgModule({
    declarations: [
        MenuComponent,
        MenuBreadcrumbComponent,
        MenuToolbarComponent,
        MenuOrderPipe
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        RouterModule,
        PipesModule,
        FormModuleV2,
        DirectivesModule,
        LocalActionsModule,
        FiltersModule,
        LinkModule,
        TranslateModule,
        AvatarModule,
        CopyrightModule,
        ProgressSpinnerModule,
        NoResultsModule,
        FormModuleV2,
        ProgressSpinnerModule,
        NoResultsModule,
        VarModule
    ],
    exports: [
        MenuComponent
    ]
})
export class MenuModule {
}
