import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ErrorsHandlerService} from "./errors-handler.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: ErrorsHandlerService
        }
    ]
})
export class ErrorsModule {
}
