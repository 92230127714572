import {Authentication} from "../app/core/authentication/authentication";
export const LOAD_ENV = async () => {

    const resp = await fetch('assets/config/config.json')
        .then((response) => response.json()) as {apiUrl: string};

    const env2 = {
        api: resp.apiUrl,
    };

    Object.assign(environment, env2);
    return env2;
};
export const environment = {
    appVersion: require('../../package.json').version,
    production: true,
    sandbox: false,
    staging: false,
    development: false,
    api: "",
    headers: Authentication.getHeaders,
    local: 'production'
};
