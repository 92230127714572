import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FormAutocompleteComponent } from "./autocomplete/form-autocomplete.component";
import {
	MatAutocompleteOptionsScrollModule
} from "../../directives/mat-autocomplete-options-scroll/mat-autocomplete-options-scroll.module";
import { FormComponent } from "./form.component";
import { FormInputComponent } from './input/form-input.component';
import { FormSelectComponent } from './select/form-select.component';
import { FormDatepickerComponent } from './datepicker/form-datepicker.component';
import { FormDateRangeComponent } from './date-range/form-date-range.component';
import { FormFabComponent } from './fab/form-fab.component';
import { FormButtonComponent } from './button/form-button.component';
import { FormSmallContentComponent } from './small-content/form-small-content.component';
import { FormActionsComponent } from './actions/form-actions.component';
import { FormSlideToggleComponent } from './slide-toggle/form-slide-toggle.component';
import { FormRadioComponent } from './radio/form-radio.component';
import { FormRequiredLabelComponent } from './required-label/form-required-label.component';
import { FormCheckboxComponent } from './checkbox/form-checkbox.component';
import { FormSliderComponent } from './slider/form-slider.component';
import { FormToggleGroupComponent } from './toggle-group/form-toggle-group.component';
import { FormChipsComponent } from './chips/form-chips.component';
import { FormColorPickerComponent } from "./color-picker/form-color-picker.component";
import { FormTextareaComponent } from "./textarea/form-textarea.component";
import { FormEditorComponent } from './editor/form-editor.component';
import { FormCardSelectComponent } from './card-select/form-card-select.component';

import { RouterModule } from "@angular/router";
import { NgxCurrencyModule } from "ngx-currency";
import { MaskPipe, NgxMaskModule } from "ngx-mask";
import { QuillModule } from "ngx-quill";
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule } from "@angular-material-components/color-picker";
import { AngularMaterialModule } from 'src/app/shared/base/angular-material.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { IconModule } from 'src/app/shared/components/icon/icon.module';
import { NoResultsModule } from 'src/app/shared/components/no-results/no-results.module';
import { ProgressSpinnerModule } from 'src/app/shared/components/progress-spinner/progress-spinner.module';
import { WindowLoadingModule } from 'src/app/shared/components/window-loading/window-loading.module';
import { FormDateDirective } from "../../directives/form/form-date.directive";
import { TranslateModule } from "@ngx-translate/core";
import { DynamicIconModule } from "../dynamic-icon/dynamic-icon.module";
import { FormButtonLoadingModule } from "../../directives/form-button-loading/form-button-loading.module";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { Translate } from "../../helpers/translate";
import { FormDateAdapterService } from "./form-date-adapter.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { RootTranslatePipe } from "../../pipes/utils/root-translate.pipe";


@NgModule({
    declarations: [
        FormComponent,
        FormInputComponent,
        FormAutocompleteComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        FormDateRangeComponent,
        FormFabComponent,
        FormButtonComponent,
        FormSmallContentComponent,
        FormActionsComponent,
        FormSlideToggleComponent,
        FormRadioComponent,
        FormRequiredLabelComponent,
        FormCheckboxComponent,
        FormSliderComponent,
        FormToggleGroupComponent,
        FormChipsComponent,
        FormColorPickerComponent,
        FormTextareaComponent,
        FormEditorComponent,
        FormCardSelectComponent,
        FormDateDirective
    ],
    imports: [
        CommonModule,
		DynamicIconModule,
        AngularMaterialModule,
        ReactiveFormsModule,
        FormsModule,
		FormButtonLoadingModule,
        RouterModule,
        NgxCurrencyModule,
        IconModule,
        NgxMaskModule.forRoot({validation: false}),
        TranslateModule,
        ProgressSpinnerModule,
        NgxMatColorPickerModule,
        WindowLoadingModule,
        DirectivesModule,
        NoResultsModule,
		DynamicIconModule,
        QuillModule.forRoot(),
        MatAutocompleteOptionsScrollModule,
    ],
    exports: [
        FormComponent,
        FormInputComponent,
        FormAutocompleteComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        FormDateRangeComponent,
        FormFabComponent,
        FormButtonComponent,
        FormSmallContentComponent,
        FormActionsComponent,
        FormSlideToggleComponent,
        FormRadioComponent,
        FormRequiredLabelComponent,
        FormCheckboxComponent,
        FormSliderComponent,
        FormToggleGroupComponent,
        FormChipsComponent,
        FormColorPickerComponent,
        FormTextareaComponent,
        FormEditorComponent,
        FormCardSelectComponent
    ],
	providers: [
		{
			provide: MAT_COLOR_FORMATS,
			useValue: { display: { colorInput: 'hex6' } }
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: Translate.configurations.defaultLanguage
		},
		{ provide: DateAdapter, useClass: FormDateAdapterService },
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'DD/MM/YYYY',
				},
				display: {
					dateInput: 'DD/MM/YYYY',
					monthYearLabel: 'MMM YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
				},
			}
		},
		{
			provide: MaskPipe,
			useClass: MaskPipe
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline'
			}
		},
		CurrencyPipe,
		RootTranslatePipe
	]
})
export class FormModuleV2 {
}
