import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionDirective } from './action/action.directive';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { KeyboardManagerItemDirective } from "./keyboard-manager/keyboard-manager-item.directive";
import { KeyboardManagerDirective } from "./keyboard-manager/keyboard-manager.directive";

@NgModule({
    declarations: [
        ActionDirective,
        AutofocusDirective,
        KeyboardManagerDirective,
        KeyboardManagerItemDirective,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ActionDirective,
        AutofocusDirective,
        KeyboardManagerDirective,
        KeyboardManagerItemDirective,
    ]
})
export class DirectivesModule{}
