<mat-toolbar color="primary"
             class="toolbar mat-elevation-z3">
    <mat-toolbar-row class="toolbar__row">
        <div class="toolbar__menu"
             *accVar="{
                myProfileUrl: 'routes.myProfile.path' | translate,
                title: ('menu.toolbar.title' | translate),
                adminTitle: ('menu.toolbar.adminTitle' | translate)
             } as auxLabels;">
            <div class="toolbar__space-menu toolbar__info">
                <acc-avatar [environment]="environment"
                            *ngIf="!userService.isAdmin"
                            class="toolbar__space-small-menu"
                            [imageUrl]="userService?.getUser?.photo?.small"
                            [queryParamsHandling]="(menuService.chooseHandling(auxLabels.myProfileUrl) | async)"
                            [link]="auxLabels.myProfileUrl"
                            tooltip="menu.toolbar.actions.myProfile.text"
                            text="menu.toolbar.actions.myProfile.text">
                </acc-avatar>
                <h1 class="toolbar__title-page">{{ userService.isAdmin ? auxLabels.adminTitle : (userService?.getUser?.name || auxLabels.title) }}</h1>
            </div>
            <acc-form-button icon="login"
                             [click]="signInService.logout?.bind(signInService)"
                             color="none"
                             theme="icon"
                             tooltip="menu.toolbar.actions.logout.text"
                             text="menu.toolbar.actions.logout.text">
            </acc-form-button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
