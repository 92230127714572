export const PASSWORD_RESET_TRANSLATE = {
	passwordReset: {
        messagesPassword: {
            update: "Código enviado com sucesso, aguarde o recebimento do email para continuar com a recuperação de senha"
        },
		messages: {
			error: "Ocorreu um erro ao enviar o e-mail",
            messagesPassword: "Código enviado com sucesso, aguarde o recebimento do email para continuar com a recuperação de senha"
		},
		titles: {
			access: "Recuperar acesso",
			emailAccess: "Por e-mail",
			smsAccess: "Por mensagem (SMS)",
			enterAccessCode: "Informe o código de acesso",
		},
		subtitles: {
			enterAccessCode: "Enviamos um SMS com o código de recuperação para o seu número de celular",
			enterCellphone: "Você receberá uma mensagem com o " +
				"código de recuperação para alterar sua senha.",
			enterEmail: "Você receberá um e-mail com o link de recuperação para alterar sua senha."
		},
		link: 'Esqueceu sua senha?',
		back: "Voltar",
		cpf: {
			title: "Acesso sem e-mail ou celular",
			text: `Se você não tem e-mail ou celular, procure seu líder, supervisor ou RH`
		},
		form: {
			fields: {
				email: {
					label: "E-mail",
					placeholder: "Informe o e-mail"
				},
				cellphone: {
					label: "Número de celular",
					placeholder: "(00) 00000-0000"
				},
				accessCode: {
					label: "Código de recuperação",
					placeholder: "Informe o código de recuperação"
				},
			},
			actions: {
				save: {
					text: "Enviar"
				},
				validateCode: {
					text: "Continuar"
				},
				generateCode: {
					text: "Enviar código"
				},
				generateAgainTime: {
					text: "Reenviar código em: {{seconds}}"
				},
				generateAgain: {
					text: "Reenviar código"
				}
			}
		}
	},
};
