import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { debounceTime, delay } from "rxjs/operators";
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';
import { FormButton } from 'src/app/shared/components/form/button/models/form-button';
import { Img } from 'src/app/shared/models/img';

import { MenuService } from "./menu.service";
import { FiltersService } from "../filters/filters.service";
import { UsersService } from "../../modules/users/users.service";
import { AppService } from "../../app.service";
import { LocalActionsService } from "../local-actions/local-actions.service";
import { environment } from "../../../environments/environment";

@Component({
    selector: 'acc-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    preserveWhitespaces: false
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('menu') menu: MatSidenav = {} as MatSidenav;
    @ViewChild('spaces', {read: ElementRef}) spaces: ElementRef;
    @ViewChild('toolbar', {read: ElementRef}) toolbar: ElementRef;
    mode: MatDrawerMode = 'side';
    createAction: FormButton = {} as FormButton;
    top = '0px';
    menuBg: null | string = null;
    version = `v ${environment.appVersion}`;

    private resizeObservable: Observable<Event> = of();
    private subscriptions: Subscription[] = [];

    constructor(
        private changeDetector: ChangeDetectorRef,
        private router: Router,
        public menuServ: MenuService,
        private filtersService: FiltersService,
        public rootServ: AppService,
        public userService: UsersService,
        private actionsService: LocalActionsService
    ) {
    }

    clickItem(): void {
        if (this.mode === 'over') this.menu?.close();
    }

    ngOnInit(): void {
        this.resizeObservable = fromEvent(window, 'resize').pipe(debounceTime(250));
        this.subscriptions.push(
            this.resizeObservable.subscribe(() => this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit())),
            this.userService.watchUserChange().subscribe(fromSwitchUser => fromSwitchUser ? this.reorderMenu() : null),
            this.menuServ.reorderMenu.asObservable().subscribe(condition => condition ? this.reorderMenu() : null),
            this.menuServ.close.asObservable().subscribe(condition => {
                if (condition) this.menu?.close();

                this.setTop();
            }),
            this.menuServ.toggle.asObservable().subscribe(condition => {
                if (Auxiliary.isBoolean(condition)) {
                    this.menu?.open();
                }

                this.setTop();
            }),
            this.actionsService.watchCreateAction().pipe(delay(25)).subscribe((createAction: FormButton) => {
                this.createAction = createAction;
                this.changeDetector.detectChanges();
            })
        );
    }

    ngAfterViewInit(): void {
        this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit());

        this.subscriptions.push(
            this.menu
                ?.openedChange
                .subscribe(() => {
                    if (this.menu?.opened && this.mode === 'over') this.filtersService.close();
                }) as Subscription
        );
    }

    changeMenuConfigurations(hideMenu: boolean): void {
        this.mode = hideMenu ? 'over' : 'side';

        if (this.menu?.opened && hideMenu) this.menu?.close();
        else if (!this.menu?.opened && !hideMenu && this.rootServ.canISeeMenu) this.menu?.open();

        this.setTop();
        this.setImgs();

        this.changeDetector.detectChanges();
    }

    reorderMenu(): void {
        setTimeout(() => {
            this.menuServ.list = [];
            this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit());
        });
    }

    setTop(): void {
        setTimeout(() => {
            const spacesHeight = this.spaces?.nativeElement?.offsetHeight || 0;
            const toolbarHeight = this.toolbar?.nativeElement?.offsetHeight || 0;

            this.top = `${!spacesHeight ? toolbarHeight - 1 : spacesHeight + toolbarHeight}px`;

            this.changeDetector.detectChanges();
        }, 100);
    }

    setImgs(): void {
        const bg = this.userService?.getUser?.account?.menuBackground;

        this.menuBg = bg ? `url(${environment.api}${bg})` : null;

        this.changeDetector.detectChanges();
    }

    get logoClient(): Img | null {
        const user = this.userService?.getUser;

        const logo = user?.account?.logo;

        return logo ? {src: `${environment.api}${logo}`, alt: 'menu.images.account'} : null;
    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }
}
