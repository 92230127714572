import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Params, Router } from "@angular/router";

import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { Translate } from 'src/app/shared/helpers/translate';
import { DialogService } from 'src/app/shared/components/dialog/dialog.service';
import { GlobalLoadingService } from 'src/app/shared/components/global-loading/global-loading.service';

import { environment } from "../../../environments/environment";
import { UsersService } from "../users/users.service";
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class SignInService {
    onLogout = new Subject();

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private dialogServ: DialogService,
        private userService: UsersService,
        private globalLoadingService: GlobalLoadingService,
        private authenticationService: AuthenticationService
    ) {
    }

    get loginUrl(): string {
        return Translate.value('routes.login.path', {initialValue: ''});
    }

    isLogged(): boolean {
        return !!this.userService.getUser?.id;
    }

    isAtLogin(): boolean {
        return this.router.url.includes(this.loginUrl.replace('/', ''));
    }

    login(parameters: Params): Observable<HttpResponse<any>> {
        return this.httpClient
            .post<HttpResponse<any>>(`${environment.api}/auth/sign_in`, {...parameters}, {observe: 'response'}).pipe(take(1));
    }

    logout(): void {
        this.onLogout.next();

        this.globalLoadingService.send({show: true, before: 3000, message: 'globalLoading.logout'});

        setTimeout(() => {
            this.httpClient
                .delete<HttpResponse<any>>(`${environment.api}/auth/sign_out`)
                .pipe(take(1))
                .subscribe(() => {
                    this.authenticationService.resetAuthentication();
                    this.dialogServ.close();
                });
        }, 500);
    }
}
