import { Component, Input } from '@angular/core';

import { FormButton } from "../button/models/form-button";

@Component({
    selector: 'acc-form-actions',
    templateUrl: './form-actions.component.html',
    styleUrls: ['./form-actions.component.scss']
})
export class FormActionsComponent {
    @Input() primaryAction: FormButton = {} as FormButton;
    @Input() secondaryAction: FormButton = {} as FormButton;
}
