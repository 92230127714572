export const MY_PROFILE = {
    myProfile: {
        messagesPassword: {
            update: "Senha alterada com sucesso"
        },
        changePassword: {
            form: {
                title: "Senha de login",
                actions: {
                    save: {
                        text: "Alterar"
                    }
                }
            },
        },
        messages: {
            delete: "Usuário excluído com sucesso",
            recover: "Usuário restaurado com sucesso",
            update: "Usuário atualizado com sucesso",
            create: "Usuário criado com sucesso"
        },
        update: {
            form: {
                actions: {
                    save: {
                        text: "Salvar"
                    }
                }
            }
        },
        create: {
            form: {
                title: "Novo usuário",
                actions: {
                    save: {
                        text: "Criar"
                    }
                }
            }
        },
        save: {
            form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome"
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail"
                    },
                    changePassword: {
                        label: "Alterar senha"
                    },
                    currentPassword: {
                        label: "Senha atual",
                        placeholder: "Informe a senha atual",
                        hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    },
                    password: {
                        label: "Senha",
                        placeholder: "Informe a senha",
                        hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    },
                    passwordConfirmation: {
                        label: "Confirmação de senha",
                        placeholder: "Informe a confirmação de senha",
                        hint: "A confirmação de senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
                    }
                }
            }
        },
        read: {
            tabs: {
                data: "Dados",
                password: "Alterar Senha"
            },
            importCsv: {
                form: {
                    actions: {
                        save: {
                            text: "Salvar"
                        }
                    },
                    fields: {
                        file: {
                            label: "CSV de importação",
                            remove: "Remover CSV",
                            add: "Adicionar CSV",
                            change: "Alterar CSV",
                            alternative: "CSV importado pelo usuário",
                            notFound: "Nenhum CSV adicionado"
                        },
                        defaultTemplate: {
                            label: "Baixe o modelo"
                        }
                    }
                },
                title: "Importar usuários"
            },
        },
    },
};
