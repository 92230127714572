import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallPipe } from './call.pipe';
import { RootTranslatePipe } from './root-translate.pipe';
import { ToStringPipe } from "./to-string.pipe";
import { NotNullPipe } from "./not-null.pipe";


@NgModule({
	declarations: [
		CallPipe,
		RootTranslatePipe,
		ToStringPipe,
		NotNullPipe
	],
	exports: [
		CallPipe,
		RootTranslatePipe,
		ToStringPipe,
		NotNullPipe
	],
	imports: [
		CommonModule
	]
})
export class UtilsPipeModule { }
