import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Translate } from 'src/app/shared/helpers/translate';
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';
import { GlobalLoadingService } from 'src/app/shared/components/global-loading/global-loading.service';

import { environment } from "../../../environments/environment";
import { UsersService } from "../../modules/users/users.service";
import { MenuService } from "../menu/menu.service";
import { Authentication } from "./authentication";
import { AppService } from "../../app.service";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    validateTokenObservable: Observable<any> | null;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UsersService,
        private menuService: MenuService,
        private root: AppService,
        private globalLoadingService: GlobalLoadingService
    ) {
    }

    get loginUrl(): string {
        return Translate.value('routes.login.path', {initialValue: ''});
    }

    validateToken(isGoingToOutsideOfSystem: boolean): void {
        if (!this.validateTokenObservable) {
            this.globalLoadingService.send({show: true, before: 2000, message: 'globalLoading.authentication'});
            this.validateTokenObservable = this.httpClient.get<any>(`${environment.api}/auth/validate_token`).pipe(take(1));

            this.validateTokenObservable.subscribe(response => {
                this.userService.setUser(response.user);
                this.validateTokenObservable = null;
                this.shouldBeRedirect(isGoingToOutsideOfSystem);
            });
        }
    }

    resetAuthentication(): void {
        let extraParams: NavigationExtras = {};

        this.route.queryParams.subscribe(params => {
            const nextPathParam = Translate.value('routes.login.nextPath');
            const nextPathValue = params?.[nextPathParam];

            if (nextPathValue && Auxiliary.removeUrlParameters(this.router.url) === this.loginUrl)
                extraParams = {queryParams: {[nextPathParam]: nextPathValue}};
        });

        Authentication.resetHeaders();
        this.userService.setUser(null);
        this.router.navigate([this.loginUrl], extraParams);
    }

    private shouldBeRedirect(isGoingToOutsideOfSystem: boolean = false): void {

        setTimeout(() => {
            const url = Auxiliary.removeUrlParameters(this.router.url.trim());
            const loginRoute = Translate.value('routes.login.path').replace('/', '');
            const queryParamsHandling = 'merge';

            if (
                isGoingToOutsideOfSystem ||
                !url ||
                url === '/' ||
                url.replace('/', '').startsWith(loginRoute)
            ) this.router.navigate([this.root.initialUrl], {queryParamsHandling});
        }, 100);
    }
}
