export const NICKNAME_TRANSLATE = {
	nicknames: {
		messages: {
            inactive: "Apelido inativado com sucesso",
			delete: "Apelido inativado com sucesso",
			update: "Apelido atualizado com sucesso",
            recover: "Apelido ativado com sucesso",
            create: "Apelido criado com sucesso"

		},
        forceSave: {
            title: "Cadastrar apelido?",
            body:  "Este apelido já está cadastrado em {{ name }}. Deseja mesmo cadastrar esse apelido?",
            back: {
                text: "Voltar"
            },
            save: {
                text: "Sim, cadastrar"
            }
        },
		list: {
			confirmInactivateDialog: {
				title: "Inativar apelido?",
				body: 'Tem certeza que deseja inativar o apelido "{{name}}"?',
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover o apelido?",
			},
			table: {
				columns: {
                    name: "Nome",
                    environmentType: "Ambiente",
                    account: "Conta",
                    id: "ID"
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
                    name: {
                    	label: "Nome",
                    	placeholder: "Informe aqui o nome",
                    	url: "nome",
                    },
                    accountId: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta",
                        url: "conta",
                    },
                },
			},
		},

	    create: {
			form: {
                title: "Novo apelido",

				actions: {
					save: {
						text: "Salvar"
					},
                    add:{
                        text: "Adicionar"
                    }
				}
			}
		},
        read: {
            tabs: {
                data: "Dados",
                // users: "Usuários"
            }
        },
        update: {
            form: {
                title: "Editar apelido",
                actions: {
                    save: {
                        text: "Salvar"
                    }
                }
            }
        },
		save: {
			form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome",
                    },
                    account: {
                        label: "Conta",
                        placeholder: "Informe aqui a conta",
                    },
                    environmentType: {
                        label: "Ambiente",
                        placeholder: "Informe aqui o ambiente",
                    },
            },
                actions: {
                    save: {
                        text: "Salvar"
                    },
                  },
				},
			}

		},

};


