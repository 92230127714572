<div class="small-content">
    <acc-icon *ngIf="(icon || svgIcon) && !iconUrl"
                [badge]="badge"
                [icon]="icon"
                [svgIcon]="svgIcon"
                [classes]="iconClasses"
                [class]="text ? 'small-content__icon small-content__icon--with-text' : 'small-content__icon'">
    </acc-icon>

    <acc-dynamic-icon [iconUrl]="iconUrl" *ngIf="!!iconUrl" [iconName]="icon"></acc-dynamic-icon>

    <span *ngIf="text" class="small-content__text">{{ text | translate }}</span>
</div>
