import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { MatSnackBarConfig } from "@angular/material/snack-bar/snack-bar-config";

import { Auxiliary } from "../../helpers/auxiliary";
import { SnackBarComponent } from "./snack-bar.component";
import { SnackBarTypesEnum } from "./models/snack-bar-types.enum";
import { FormButton } from "../form/button/models/form-button";

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    static time = 4000;
    static longTime = 8000;

    reference: MatSnackBarRef<SnackBarComponent> = {} as MatSnackBarRef<SnackBarComponent>;

    constructor(private materialAngularSnackBar: MatSnackBar){}

    create(message: string, success: boolean, options: MatSnackBarConfig = {}): void{
        if(message && Auxiliary.isString(message) && Auxiliary.isBoolean(success)){
            this.reference = this.materialAngularSnackBar.openFromComponent(
                SnackBarComponent,
                this.createSnackBarConfigurations(message, success, options)
            );
        }
    }

    close(): void{
        this.reference?.dismissWithAction();
    }

    private createSnackBarConfigurations(message: string, success: boolean, options: MatSnackBarConfig = {}): MatSnackBarConfig{
        const configurations = {
            duration: SnackBarService.time,
            horizontalPosition: 'start',
            verticalPosition: 'bottom',
            panelClass: [],
            data: {
                message: '',
                mode: success ? SnackBarTypesEnum.SUCCESS : SnackBarTypesEnum.ERROR,
                action: {
                    text: "snackBar.actions.close.text",
                    click: this.close.bind(this),
                    condition: () => false,
                } as FormButton
            }
        };

        switch(configurations.data.mode){
            case SnackBarTypesEnum.SUCCESS:
                configurations.data.message = message || 'snackBar.messages.success';
                break;
            case SnackBarTypesEnum.ERROR:
                configurations.data.message = message || 'snackBar.messages.error';
                configurations.duration = SnackBarService.longTime;
                configurations.data.action.condition = () => true;
                break;
        }

        return Auxiliary.assignAllObjects(configurations, options) as MatSnackBarConfig;
    }
}
