import {Component} from '@angular/core';

import {SignInService} from "../../../modules/sign-in/sign-in.service";
import {UsersService} from "../../../modules/users/users.service";
import {MenuService} from "../menu.service";
import {AppService} from "../../../app.service";
import {MenuToolbarService} from "./menu-toolbar.service";
import {environment as env} from "../../../../environments/environment";

@Component({
    selector: 'acc-menu-toolbar',
    templateUrl: './menu-toolbar.component.html',
    styleUrls: ['./menu-toolbar.component.scss'],
    preserveWhitespaces: false
})
export class MenuToolbarComponent {
    environment = env;

    constructor(
        public signInService: SignInService,
        public userService: UsersService,
        public menuService: MenuService,
        public rootServ: AppService,
        public toolbarServ: MenuToolbarService
    ) {
    }
}
