import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { PipesModule } from 'src/app/shared/pipes/pipes.module';

import { DynamicIconComponent } from './dynamic-icon.component';
import { UtilsPipeModule } from '../../pipes/utils/utils-pipe.module';


@NgModule({
    declarations: [
        DynamicIconComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        UtilsPipeModule,
        MatIconModule,
        ],
    exports: [
        DynamicIconComponent
    ]
})
export class DynamicIconModule {
}
