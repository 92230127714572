export const CUSTOMER_PORTAL_TRANSLATE = {
	customerPortal: {
		messages: {
            inactive: "Portal do cliente inativado com sucesso",
			delete: "Portal do cliente inativado com sucesso",
			update: "Portal do cliente removida com sucesso",
            recover: "Portal do clientere ativada com sucesso",
            create: "Portal do cliente criada com sucesso"

		},
		list: {
			confirmInactivateDialog: {
				title: "Inativar portal do cliente?",
				body: "Tem certeza que deseja inativar o portal do cliente {{version}}?",
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover o portal do cliente?",
			},
			table: {
				columns: {
					// id: "ID",
                    androidVersion: "Código da versão",
                    applicationType: "Tipo de dispositivo",
                    updateType: "Atualização",
                    productType: "Produto"
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
                    androidVersion: {
						label: "Código da versão",
						placeholder: "Informe aqui o código da versão",
						url: "codigo-da-versao",
					},
                    applicationType: {
						label: "Tipo",
						placeholder: "Informe aqui o tipo de dispositivo",
						url: "tipo-de-dispositivo",
					},
                    updateType: {
                        label: "Atualização",
                        placeholder: "Informe aqui a atualização",
                        url: "atualizacao",
                    },
                    productType: {
                        label: "Produto",
                        placeholder: "Informe aqui o produto",
                        url: "produto",
                    },
				},
			},
		},
	    create: {
			form: {
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		update: {
			form: {
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		read: {
			tabs: {
				data: "Dados",
			}
		},
		save: {
			form: {
                titles: {
                    environmentTest: "Ambiente Teste"
                },
				fields: {
                    baseUrl: {
                        label: "URL da API",
                        placeholder: "Informe aqui a URL da API"
                    },
                    baseUrlWeb: {
                        label: "URL da web",
                        placeholder: "Informe aqui a URL da web"
                    },
                    passwordRecoverUrl: {
                        label: "URL de recuperação de senha",
                        placeholder: "Informe aqui a URL de recuperação de senha"
                    },
                    logo: {
                        label: "Imagem do login",
                        remove: "Remover imagem login",
                        add: "Adicionar imagem do login",
                        change: "Alterar imagem do login",
                        alternative: "Imagem do login adicionada",
                        notFound: "Nenhuma imagem do login adicionada ainda"
                    }
                },
                actions: {
                    save: {
                        text: "Salvar"
                    },
                  },
				},
			}
		},
};


