import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Translate } from 'src/app/shared/helpers/translate';
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';

import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PasswordEditService{
    constructor(private http: HttpClient, private router: Router){}

    change(form: FormGroup): Observable<null>{
        return this.http.patch<null>(`${environment.api}/auth/password`, form.getRawValue()).pipe(take(1));
    }

    isAtPasswordEdit(): boolean{
        return Auxiliary.removeUrlParameters(this.router.url) === Translate.value('routes.passwordEdit.path');
    }
}
