import { Pipe, PipeTransform } from '@angular/core';

import { Auxiliary } from "src/app/shared/helpers/auxiliary";
import { Generic } from "src/app/shared/models/generic";

import { Menu } from "../../models/menu";
import { UsersService } from "../../../modules/users/users.service";

@Pipe({
    name: 'menuOrder'
})
export class MenuOrderPipe implements PipeTransform {
    constructor(private userService: UsersService) {
    }

    transform(list: Menu[]): Generic[] {
        const groups: Generic[] = [];

        list.forEach(item => {
            let currentGroup: Generic = {};

            groups.forEach(group => group.name === item.group ? currentGroup = group : null);
            if (this.validateIfItIsEnabled(item)) {
                if (Auxiliary.objectHasKeysLength(currentGroup)) currentGroup.list.push(item);
                else groups.push({name: item.group, list: [item]});
            }
        });

        return groups;
    }

    private validateIfItIsAccountAdmin(item: Menu): boolean {
        return Auxiliary.isBoolean(item.isAccountAdmin) ? item.isAccountAdmin === this.userService.getUser?.isAccountAdmin : true;
    }

    private validateIfItIsCustomer(item: Menu) {
        return Auxiliary.isBoolean(item.isCustomer) ? item.isCustomer === this.userService.getUser?.isCustomer : true;
    }

    private validateIfItIsEnabled(item: Menu): boolean {

        if (this.validateIfItIsAccountAdmin(item) || this.validateIfItIsCustomer(item)) {
            const codes = this.userService.getUser?.tools;
            if (item.permission) {
                return !!codes?.includes(item.permission);
            }
            return true;
        }

        return false;

    }

}
