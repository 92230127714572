import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

import { GlobalLoadingService } from 'src/app/shared/components/global-loading/global-loading.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';

import { SignInService } from "./modules/sign-in/sign-in.service";
import { AppExtrasService } from "./app-extras.service";
import { AppService } from "./app.service";
import { Subscription } from "rxjs";
import { UsersService } from "./modules/users/users.service";
import { PasswordResetService } from "./modules/password-reset/password-reset.service";
import { PasswordEditService } from "./modules/password-edit/password-edit.service";

@Component({
    selector: 'acc-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    preserveWhitespaces: false
})
export class AppComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    constructor(
        public signInService: SignInService,
        public globalLoadingService: GlobalLoadingService,
        public passwordResetService: PasswordResetService,
        public passwordEditService: PasswordEditService,
        private renderer: Renderer2,
        private appServ: AppService,
        private rootExtrasService: AppExtrasService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private usersService: UsersService,
        private themeService: ThemeService,
    ) {
    }

    ngOnInit(): void {
        this.rootExtrasService.watchTitle();
        this.registerIcons();
        this.changeTheme();

    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    private print(): void {
        const {color, backgroundColor} = getComputedStyle(document.querySelector('noscript') as Element);
        const translate = `root.workWithUs.`;

        this.appServ.color = color;
        this.appServ.backgroundColor = backgroundColor;


    }

    private registerIcons(): void {
        [
            {
                name: 'barcode',
                url: "./assets/md-alternative-icons/barcode.svg"
            }
        ].forEach(icon => this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)));
    }

    private changeTheme(): void {
        this.subscriptions.push(
            this.usersService.watchUserChange().subscribe(() => {
                const user = this.usersService.getUser;

                if (user?.id && user?.account?.primaryColors && user?.account?.secondaryColors) {
                    this.themeService.setApplicationTheme(this.themeService.PRIMARY_KEY, user.account.primaryColors);
                    this.themeService.setApplicationTheme(this.themeService.SECONDARY_KEY, user.account.secondaryColors);
                }
            })
        );
    }
}
