export const ACTIONS_TRANSLATE = {
    actions: {
        create: "Criar",
        add: "Adicionar",
        import: "Importar",
        save: "Salvar",
        generateReport: "Gerar relatório"

    }
};
