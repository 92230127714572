
<mat-toolbar color="accent"
             *ngIf="service?.breadcrumb?.length"
             [ngClass]="{'breadcrumb--new-active': !!createAction.text, 'breadcrumb--hidden': !root.canISeeBreadcrumb}"
             class="breadcrumb">
    <mat-toolbar-row class="breadcrumb__row">
        <div class="breadcrumb__path">
            <acc-form-button [click]="open.bind(this)"
                             icon="menu"
                             class="breadcrumb__menu"
                             theme="icon"
                             color="primary"
                             tooltip="menu.breadcrumb.actions.menu.text"
                             text="menu.breadcrumb.actions.menu.text">
            </acc-form-button>
            <ol class="breadcrumb__list">
                <li *ngFor="let item of service.breadcrumb; let last = last;" class="breadcrumb__item">
                    <a [ngClass]="{'breadcrumb__link--disabled': last}"
                       [attr.aria-disabled]="last"
                       class="breadcrumb__link"
                       [tabindex]="last ? -1 : 0"
                       [routerLink]="item.url"
                       target="_self"
                       rel="noopener">
                        {{ item.label }}
                    </a>
                    <mat-icon class="breadcrumb__icon" aria-hidden="true" *ngIf="!last">navigate_next</mat-icon>
                </li>
            </ol>
        </div>
        <div class="breadcrumb__actions">
            <acc-local-actions where="breadcrumb"></acc-local-actions>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="breadcrumb__new">
        <acc-form-fab *ngIf="!!createAction.text"
                      [href]="createAction.href"
                      [icon]="createAction.icon"
                      [text]="createAction.text"
                      (click)="createAction?.click && createAction.click()">
        </acc-form-fab>
    </mat-toolbar-row>
</mat-toolbar>
