import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";

import { TranslateModule } from "@ngx-translate/core";

import { NoResultsComponent } from "./no-results.component";

@NgModule({
    declarations: [
        NoResultsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule
    ],
    exports: [
        NoResultsComponent
    ]
})
export class NoResultsModule { }
