import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from "@angular/router";

import { filter } from "rxjs/operators";
import { Auxiliary } from "src/app/shared/helpers/auxiliary";

import { LocalActionsService } from "./core/local-actions/local-actions.service";

@Injectable({
    providedIn: 'root'
})
export class AppRoutesService {
    private previousUrl = '';
    private currentUrl = '';

    constructor(private router: Router, private actions: LocalActionsService) {
        this.router
            .events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                if (event instanceof RouterEvent) {
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = event.url;

                    if (this.previousUrl && this.currentUrl && !this.areLastRoutesEqual())
                        this.actions.sendActions({});
                }
            });
    }

    areLastRoutesEqual(): boolean {
        return Auxiliary.removeUrlParameters(this.currentUrl)
            ===
            Auxiliary.removeUrlParameters(this.previousUrl);
    }
}
