import {NgModule} from '@angular/core';

import {FormButtonLoadingDirective} from "./form-button-loading.directive";

@NgModule({
	declarations: [FormButtonLoadingDirective],
	imports: [],
	exports: [FormButtonLoadingDirective]
})
export class FormButtonLoadingModule {
}
