import {
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
	ViewContainerRef
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `
        button[mat-button][loading],
        button[mat-raised-button][loading],
        button[mat-icon-button][loading],
        button[mat-fab][loading],
        button[mat-mini-fab][loading],
        button[mat-stroked-button][loading],
        button[mat-flat-button][loading]
    `,
})
export class FormButtonLoadingDirective implements OnChanges {
    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Input() color: ThemePalette;

    private spinnerFactory: ComponentFactory<MatProgressSpinner>;
    private spinner: ComponentRef<MatProgressSpinner>|null;

    constructor(
        private matButton: MatButton,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2
    ) {
        this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    }

    ngOnChanges(changes: SimpleChanges): void{
        if(!changes.loading) return;

        if (changes.loading.currentValue) {
            this.matButton.disabled = true;
            // eslint-disable-next-line no-underscore-dangle
            this.matButton._elementRef.nativeElement.classList.add('mat-loading');
            this.createSpinner();
        } else if(!changes.loading.firstChange){
            this.matButton.disabled = this.disabled as boolean;
            // eslint-disable-next-line no-underscore-dangle
            this.matButton._elementRef.nativeElement.classList.remove('mat-loading');
            this.destroySpinner();
        }
    }

    private createSpinner(): void {
        if (!this.spinner) {
            const color = getComputedStyle(this.viewContainerRef.element.nativeElement).getPropertyValue('color');

            this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
            this.spinner.instance.color = this.color;
            this.spinner.instance.diameter = 20;
            this.spinner.instance.mode = 'indeterminate';

            // eslint-disable-next-line no-underscore-dangle
            this.renderer.appendChild(this.matButton._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);

            if(this.color !== 'primary' && this.color !== 'accent' && this.color !== 'warn'){
                setTimeout(() => {
                    try{
                        this.renderer
                            .setStyle(
                                // eslint-disable-next-line no-underscore-dangle
                                this.spinner
                                    ?.instance
                                    ._elementRef
                                    .nativeElement
                                    .querySelector('.mat-progress-spinner circle, .mat-spinner circle'),
                                'stroke',
                                color
                            );
                    } catch(error){}
                }, 50);
            }
        }
    }

    private destroySpinner(): void{
        if(this.spinner){
            this.spinner?.destroy?.();
            this.spinner = null;
        }
    }
}
