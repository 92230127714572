import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { FormBuilder, FormGroup } from '@angular/forms';

import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from "rxjs/operators";
import { Translate } from 'src/app/shared/helpers/translate';
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';
import { FormButton } from 'src/app/shared/components/form/button/models/form-button';
import { Generic } from 'src/app/shared/models/generic';
import { FormService } from 'src/app/shared/components/form/form.service';

import { Filter } from '../../shared/models/filter';
import { FilterField } from '../../shared/models/filter-field';
import { FiltersService } from './filters.service';
import { FilterUrlTypesEnum } from '../../shared/enums/filter-url-types.enum';
import { TabsService } from "../../shared/components/tabs/tabs.service";
import { FilterClearTypesEnum } from "../../shared/enums/filter-clear-types.enum";
import { TablePaginatorService } from "../../shared/components/table/paginator/table-paginator.service";

@Component({
    selector: 'acc-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, Filter, OnDestroy {
    @ViewChild('filters') filters: MatSidenav = {} as MatSidenav;

    formsHelper = FormService;
    component: any;
    form: FormGroup = this.formBuilder.group({});
    defaultValues: Generic = {};
    fields: FilterField[] = [];
    mode: MatDrawerMode = 'side';

    save: FormButton = {
        color: "primary",
        theme: "raised",
        type: "submit",
        text: "filters.actions.save.text",
        condition: () => true,
        click: () => this.search.bind(this)
    };

    clear: FormButton = {
        color: "accent",
        theme: "stroked",
        condition: () => true,
        click: this.clearAction.bind(this),
        type: "button",
        action: FilterClearTypesEnum.CLEAR_ALL,
        text: 'filters.actions.clear.text'
    };

    private subscriptions: Subscription[] = [];
    private canUseLocalStorageParams = true;

    constructor(
        private formBuilder: FormBuilder,
        private filtersService: FiltersService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private tabsService: TabsService,
        private tablePaginatorService: TablePaginatorService
    ) {
    }

    static manipulateFieldToURL(parameters: Params, name: string = '', urlParameter: string = ''): void {
        const fieldValue = parameters[name];

        delete parameters[name];

        if (
            !Auxiliary.isUndefined(fieldValue) &&
            !Auxiliary.isNull(fieldValue) &&
            !Auxiliary.isEmptyString(fieldValue)
        ) parameters[urlParameter] = Auxiliary.removeDoubleQuotationMarks(JSON.stringify(fieldValue));
    }

    ngOnInit(): void {
        this.setForm(this.formBuilder.group({}));
        this.setFields([]);

        this.subscriptions.push(
            this.filtersService
                .onToggle()
                .subscribe(() => this.filters?.toggle()),
            this.filtersService
                .onClose()
                .subscribe(() => this.close()),
            this.filtersService
                .watchFilters()
                .subscribe(filters => {
                    this.setComponent(filters?.component);
                    this.setForm(filters?.form);
                    this.setFields(filters?.fields);
                    this.setClear(filters?.clear);
                    this.setFieldValueByURL();
                    this.sendActivesFilters();
                }),
            this.router
                .events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => this.close()),
            this.activatedRoute.queryParams.subscribe(() => this.sendActivesFilters())
        );

        this.setMode();
        this.setResizeEvent();
    }

    search(callGetOnComponent: boolean = true): void {
        this.canUseLocalStorageParams = false;

        const parameters = this.formsHelper.createParametersByForm(this.form as FormGroup);

        this.putParametersToURL(parameters);

        if (callGetOnComponent) this.sendParametersToComponent(parameters);

        if (this.mode === 'over') this.close();
    }

    close(): void {
        this.filters?.close();
        this.tabsService.sendUpdatePagination(true);
    }

    isModelDateRange(field: FilterField): boolean {
        return field.model === 'date-range';
    };

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    private setClear(clear: FormButton | undefined): void {
        if (clear) this.clear = Auxiliary.assignAllObjects(this.clear, clear) as FormButton;
    }

    private setForm(form: FormGroup): void {
        if (form) {
            this.form = form;
            this.defaultValues = Auxiliary.copy(form.getRawValue());
        }
    }

    private setFields(fields: FilterField[]): void {
        if (fields?.length) {
            this.fields = fields.map(field => {
                if (field.urlParameter) field.urlParameter = Translate.value(field.urlParameter);
                if (field.startUrlParameter) field.startUrlParameter = Translate.value(field.startUrlParameter);
                if (field.endUrlParameter) field.endUrlParameter = Translate.value(field.endUrlParameter);

                return field;
            });
        } else this.fields = [];
    }

    private setComponent(component: any): void {
        if (component) {
            this.canUseLocalStorageParams = true;
            this.component = component;
        }
    }

    private setMode(): void {
        if (window.innerWidth >= 1200) this.mode = 'side';
        else this.mode = 'over';
    }

    private setResizeEvent(): void {
        this.subscriptions.push(
            fromEvent(window, 'resize').pipe(debounceTime(1000)).subscribe(() => this.setMode())
        );
    }

    private getActivesFiltersByParameters(parameters: Params = {}): number {
        const parametersCopy = Auxiliary.copy(parameters);

        for (const key in parametersCopy) {
            if (parametersCopy.hasOwnProperty(key)) {
                const keys = [
                    this.isDateRangeAllFilled(parametersCopy, this.fields || [])
                    // this.formsHelper.hasNotDefaultFieldChanged(parametersCopy, key, this.defaultValues, this.form)
                ];

                keys.forEach(newKey => delete parametersCopy[Auxiliary.asKeyof(newKey, parametersCopy)]);
            }
        }

        return Auxiliary.removeInvalidValuesFromArray(Object.values(parametersCopy)).length;
    }

    private clearAction(): void {
        switch (this.clear?.action) {
            case FilterClearTypesEnum.DEFAULT_FILTERS:
                this.form?.patchValue(this.defaultValues);
                break;
            case FilterClearTypesEnum.CLEAR_ALL:
                if (this.form) this.formsHelper.clearForm(this.form);
                break;
        }
    }

    private chooseMethodToManipulate(action: FilterUrlTypesEnum): any {
        switch (action) {
            case FilterUrlTypesEnum.MANIPULATE_TO_URL:
                return FiltersComponent.manipulateFieldToURL;
            case FilterUrlTypesEnum.MANIPULATE_ON_URL:
                return this.manipulateFieldOnURL.bind(this);
        }
    }

    private eachFields(action: FilterUrlTypesEnum, parameters: Params): void {
        this.fields?.forEach(field => {
            const method = this.chooseMethodToManipulate(action);

            if (this.isModelDateRange(field)) {
                method(parameters, field.startName, field.startUrlParameter);
                method(parameters, field.endName, field.endUrlParameter);
            } else method(parameters, field.name, field.urlParameter);
        });
    }

    private setFieldValueByURL(): void {
        this.subscriptions.push(
            this.activatedRoute
                .queryParams
                .subscribe(parameters => {
                    this.eachFields(
                        FilterUrlTypesEnum.MANIPULATE_ON_URL,
                        {
                            ...(parameters || {}),
                            ...((this.canUseLocalStorageParams && this.component?.localStorageParams) || {})
                        }
                    );
                })
        );

        this.search(false);
    }

    private manipulateFieldOnURL(parameters: Params, name: string = '', urlParameter: string = ''): void {
        const valueByURL = Auxiliary.setCorrectType(parameters[urlParameter]);
        const objectWithValue: Generic = {};
        const fieldValue = this.form?.get(name)?.value;
        const value = !Auxiliary.isNull(valueByURL) && !Auxiliary.isUndefined(valueByURL) ? valueByURL : fieldValue;

        objectWithValue[name] = value;

        if (!Auxiliary.isNull(value) && !Auxiliary.isUndefined(value)) this.form?.patchValue(objectWithValue);

        this.changeDetector.detectChanges();
    }

    private putParametersToURL(parameters: Params): void {
        const newParameters = {...parameters};

        this.eachFields(FilterUrlTypesEnum.MANIPULATE_TO_URL, newParameters);

        setTimeout(() => {
            this.tablePaginatorService.setParameters({...this.tablePaginatorService.getParameters(), page: 1});
            this.router.navigate([],
                {
                    queryParams: Object.assign(
                        newParameters,
                        this.tabsService.getParametersOnURL(),
                        this.tablePaginatorService.getTranslatedParameters()
                    ),
                    relativeTo: this.activatedRoute
                }
            );
        });
    }

    private isDateRangeAllFilled(
        parametersObject: Params,
        fields: FilterField[],
        propertiesToEvaluate = ['startName', 'endName'],
        sendProperty = 'endName'
    ): string {
        const dateRangeField = fields.filter(field => this.isModelDateRange(field))[0] as Generic;
        const areThereBothValues = propertiesToEvaluate.every(
            propertyName =>
                dateRangeField
                &&
                parametersObject.hasOwnProperty(dateRangeField[propertyName])
                &&
                parametersObject[Auxiliary.asKeyof(dateRangeField[propertyName], parametersObject)]
        );

        return areThereBothValues ? dateRangeField[sendProperty] : undefined;
    };

    private sendActivesFilters(): void {
        setTimeout(() =>
                this.filtersService.sendActives(
                    this.getActivesFiltersByParameters(
                        this.formsHelper.createParametersByForm(this.form as FormGroup)
                    )
                )
            , 50);
    }

    private sendParametersToComponent(parameters: Params): void {
        this.component.get(parameters);
    }
}
