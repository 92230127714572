import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[accAutofocus]'
})
export class AutofocusDirective implements OnInit{
    @Input() accAutofocus = false;

    constructor(private elementReference: ElementRef){}

    ngOnInit(): void{
        if(this.accAutofocus) setTimeout(() => this.elementReference.nativeElement.focus(), 250);
    }
}
