<mat-form-field [appearance]="appearance"
                [floatLabel]="floatLabel"
                [formGroup]="form"
                [hintLabel]="hintLabel | translate"
                class="autocomplete">
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>
    <input #formInput
           #trigger="matAutocompleteTrigger"
           (focus)="onFocus()"
           (input)="onInput()"
           [attr.aria-label]="label | translate"
           [formControlName]="name"
           [accAutofocus]="autofocus"
           [matAutocomplete]="matAutocomplete"
           [name]="name"
           [placeholder]="placeholder | translate"
           [required]="required"
           aria-label=""
           autocomplete="off"
           matInput
           type="text">
    <acc-form-button
            (click)="clearField()"
            [disabled]="disabledButton.bind(this)"
            [loading]="false"
            color="primary"
            icon="clear"
            matSuffix
            text="form.autocomplete.clear"
            theme="icon"
            tooltip="form.autocomplete.clear"
            type="button">
    </acc-form-button>
    <mat-autocomplete #matAutocomplete="matAutocomplete"
                      (accOptionsScroll)="onScroll()"
                      [displayWith]="displayNameProperty.bind(this)">
        <mat-option *ngIf="!isLoading && !options.length" disabled="true">{{ notFoundMessage | translate }}</mat-option>

        <mat-option (onSelectionChange)="onSelectValue($event, option)"
                    *ngFor="let option of (isNotAsynchronous ? (filteredOptions | async) : options)"
                    [value]="option[sendProperty]">
            {{ option[nameProperty] | translate }}
        </mat-option>
        <mat-option *ngIf="canGoToTheNextPage" disabled="true">
            <mat-spinner *ngIf="isLoading"
                         [diameter]="30"
                         [strokeWidth]="3"
                         class="autocomplete__loading"
                         mode="indeterminate">
            </mat-spinner>
        </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="icon" aria-hidden="true" matSuffix>{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
