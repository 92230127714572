import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDateFormats } from "@angular/material/core/datetime/date-formats";
import { MatInput } from "@angular/material/input";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";

import { Subscription } from "rxjs";

import { FormService } from "../form.service";
import { FormDateService } from "../form-date.service";
import { Moment } from 'src/app/shared/helpers/moment';
import { Generic } from 'src/app/shared/models/generic';
import { MatDatepicker } from '@angular/material/datepicker';
import { Auxiliary } from 'src/app/shared/helpers/auxiliary';

@Component({
    selector: 'acc-form-datepicker',
    templateUrl: './form-datepicker.component.html',
    styleUrls: ['./form-datepicker.component.scss'],
    preserveWhitespaces: false,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        }
    ]
})
export class FormDatepickerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dateInput') dateInput: ElementRef;
    @ViewChild(MatInput) matInput: MatInput;
    @Output() onYearSelect = new EventEmitter<{
        normalizedYear: Moment;
        datepicker: MatDatepicker<any>;
        date: FormControl;
    }>();
    @Output() onMonthSelect = new EventEmitter<{
        normalizedMonth: Moment;
        datepicker: MatDatepicker<any>;
        date: FormControl;
    }>();
    @Input() label = '';
    @Input() type = '';
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() icon = '';
    @Input() hint = '';
    @Input() hintLabel = '';
    @Input() placeholder = '';
    @Input() required: boolean;
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() closeOnMonthSelect = false;
    @Input() closeOnYearSelect = false;
    @Input() name = '';
    @Input() form: FormGroup;
    @Input() formatDate = 'DD/MM/YYYY';
    @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
    @Input() tooltip = '';
    @Input() iconTooltip = '';

    textMaskConfig: Generic = {mask: []};
    field: FormControl;
    formsHelper = FormService;
    filter: any;
    subscriptions: Subscription[] = [];
    date = new FormControl(Moment.moment());

    constructor(@Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, public dateServ: FormDateService) {
    }

    @Input('filter')
    set setFilter(filter: any) {
        this.filter = filter || (() => true);
    }

    ngOnInit(): void {
        this.field = this.formsHelper.getField(this.form as FormGroup, this.name);
        this.required = Auxiliary.isBoolean(this.required) ? this.required : this.formsHelper.getRequired(this.field);
        this.dateFormats.display.dateInput = this.formatDate;
        this.dateFormats.parse.dateInput = this.formatDate;
        this.textMaskConfig.mask = this.dateServ.setMask(this.formatDate);

        this.watchFieldChanges();
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.dateServ.watchInputChange(this.dateInput as ElementRef, this.matInput as MatInput));
    }

    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    onYearSelected(normalizedYear: Moment, datepicker: MatDatepicker<any>, date: FormControl) {
        // @ts-ignore
        this.dateServ.chosenYearHandler(normalizedYear, date);

        this.onYearSelect.emit({
            normalizedYear,
            datepicker,
            date
        });
    }

    onMonthSelected(normalizedMonth: Moment, datepicker: MatDatepicker<any>, date: FormControl) {
        // @ts-ignore
        this.dateServ.chosenMonthHandler(normalizedMonth, date);

        this.onMonthSelect.emit({
            normalizedMonth,
            datepicker,
            date
        });
    }

    private watchFieldChanges(): void {
        this.subscriptions.push(
            this.field.valueChanges.subscribe(value => {
                if (Auxiliary.isString(value) && value.length >= 13)
                    this.field.setValue(
                        new Date(value),
                        {emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false}
                    );
            })
        );
    }
}
