import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Params } from "@angular/router";

import { Translate } from 'src/app/shared/helpers/translate';
import { Generic } from 'src/app/shared/models/generic';

import { BaseListService } from "../../../base/list/base-list.service";

@Injectable({
    providedIn: 'root'
})
export class TablePaginatorService extends MatPaginatorIntl {
    firstPageLabel = '';
    lastPageLabel = '';
    itemsPerPageLabel = '';
    nextPageLabel = '';
    previousPageLabel = '';
    getRangeLabel: (page: number, pageSize: number, length: number) => string;

    private parameters: Params = {
        page: this.baseListService.defaultPage,
        perPage: this.baseListService.defaultPerPage()
    };

    constructor(private translateService: TranslateService, private baseListService: BaseListService) {
        super();

        this.translateService
            .get('table.paginator')
            .subscribe((labels: Generic) => {
                this.firstPageLabel = labels.firstPageLabel;
                this.lastPageLabel = labels.lastPageLabel;
                this.itemsPerPageLabel = labels.itemsPerPageLabel;
                this.nextPageLabel = labels.nextPageLabel;
                this.previousPageLabel = labels.previousPageLabel;
            });

        this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
            let rangeLabel = '';
            const startIndex = page * pageSize;
            length = Math.max(length, 0);

            this.translateService
                .get(
                    'table.paginator.rangeLabel',
                    {
                        index: startIndex + 1,
                        limit: startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize,
                        length
                    }
                )
                .subscribe((label: string) => rangeLabel = label);

            return rangeLabel;
        };
    }

    setParameters(parameters: Params): void {
        this.parameters = parameters;
    }

    getParameters(): Params {
        return this.parameters;
    }

    getTranslatedParameters(): Params {
        return {
            [Translate.value('table.paginator.pageUrl')]: this.parameters.page,
            [Translate.value('table.paginator.perPageUrl')]: this.parameters.perPage,
        };
    }
}
