import {Inject, InjectionToken, Optional, Pipe, PipeTransform} from '@angular/core';
import {TranslatorService} from "../../services/translator.service";
import { Generic } from '../../models/generic';

export interface TranslationConfig {
	translator: Translator;
}

export const TRANSLATION_CONFIG_TOKEN = new InjectionToken<TranslationConfig>('translation-config');

export interface Translator {
	value: ((value?: string, params?: Generic, root?: string) => string);
	create: ((root?: string, params?: Generic) => Translator);
}

@Pipe({
	name: 'translateRoot'
})
export class RootTranslatePipe implements PipeTransform {

	constructor(
		private defaultTranslator: TranslatorService,
		@Inject(TRANSLATION_CONFIG_TOKEN) @Optional() private config?: TranslationConfig,
	) {
	}

	transform(value: string, translator = this.defaultTranslator, params?: Generic): unknown {
		return translator.value(value, params);
	}
}
