import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
	selector: "[accVar]",
})
export class VarDirective implements OnInit {
	@Input()
	set accVar(context: any) {
		this.context.$implicit = this.context.accVar = context;
	}

	context: any = {};

	constructor(private ref: ViewContainerRef, private templateRef: TemplateRef<any>){}

	ngOnInit(): void {
		this.ref.clear();
		this.ref.createEmbeddedView(this.templateRef, this.context);
    }
}
