import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { fromEvent } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { Auxiliary } from 'src/app/shared/helpers/auxiliary';
import { Generic } from 'src/app/shared/models/generic';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ErrorsHandlerService implements ErrorHandler {
    private messages: Generic = {};
    private translateService: TranslateService = {} as TranslateService;

    constructor(
        private snackBarService: SnackBarService,
        private injector: Injector,
    ) {
        this.init();
    }

    static isKnownError(error: any): boolean {
        if (error instanceof HttpErrorResponse)
            return [0, 401, 403, 404, 422, 500, 504].includes(error?.status) ||
                error?.error instanceof Blob ||
                Auxiliary.isModuleLoadingError(error);
        else return false;
    }

    init(): void {
        setTimeout(() => {
            this.translateService = this.injector.get(TranslateService);

            this.translateService
                .get("errors")
                .subscribe(messagesError => this.messages = messagesError);

            fromEvent(window, 'offline').subscribe(() => this.errorConnection(this.messages.connectionDown));
            fromEvent(window, 'online').subscribe(() => this.snackBarService.create(this.messages.connectionComeBack, true));
        }, 2000);
    }

    handleError(error: any): void {
        if (Auxiliary.isModuleLoadingError(error)) this.errorConnection(this.messages.moduleImport);
        if (environment.development) console.error(error);
    }

    private errorConnection(message: string): void {
        this.snackBarService.create(message, false);
    }
}
