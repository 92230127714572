import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    ActivatedRoute
} from '@angular/router';

import {Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

import {SignInService} from "../../../modules/sign-in/sign-in.service";
import {Authentication} from "../../authentication/authentication";
import {AuthenticationService} from "../../authentication/authentication.service";
import {UsersService} from "../../../modules/users/users.service";
import {MenuService} from "../../menu/menu.service";
import {AppService} from "../../../app.service";

@Injectable({
    providedIn: 'root',
})
export class RoutesGuard implements CanActivate {
    constructor(
        private signInService: SignInService,
        private userService: UsersService,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private root: AppService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private menuServ: MenuService
    ) {
    }

    routesStartsWith(route: ActivatedRouteSnapshot, url: string): boolean {
        return !!route?.routeConfig?.path?.startsWith(url);
    }

    getTranslatedUrl(translation: string): string {
        let url = '';

        this.translateService.get(translation).subscribe(path => url = path.replace('/', ''));

        return url;
    }

    canActivate(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const urlLogin = this.getTranslatedUrl('routes.login.path');
        const nextPath = this.getTranslatedUrl('routes.login.nextPath');
        const urlConfig = this.getTranslatedUrl('routes.configurations.path');
        const urlPasswordReset = this.getTranslatedUrl('routes.passwordReset.path');
        const urlPasswordEdit = this.getTranslatedUrl('routes.passwordEdit.path');
        const isGoingToOutsideOfSystem = [urlLogin, urlPasswordReset ,urlPasswordEdit].some(url => this.routesStartsWith(route, url));
        const isLogged = this.signInService.isLogged();
        const validateTokenRequest = !isLogged && Authentication.isThereAccessToken();
        const backPage = isLogged && isGoingToOutsideOfSystem && this.router.url && this.router.url !== "/";
        const backToLogin = !isLogged && !isGoingToOutsideOfSystem;
        const cantGoToConfig = !this.userService.isAccountAdmin && this.routesStartsWith(route, urlConfig);

        this.updateMenu(route, urlConfig);

        if (validateTokenRequest) {
            this.authenticationService.validateToken(isGoingToOutsideOfSystem);

            return true;
        }

        if (cantGoToConfig) {
            if (isLogged) {
                if (this.userService.isAdmin) this.location.back();
                else this.router.navigateByUrl(this.root.initialUrl);
            } else this.goToLogin(urlLogin, nextPath);

            return false;
        }

        if (backPage) {
            this.location.back();

            return false;
        }

        if (backToLogin) {
            this.goToLogin(urlLogin, nextPath);

            return false;
        }

        return true;
    }

    private updateMenu(route: ActivatedRouteSnapshot, urlConfig: string): void {
        const startsWith = (currentUrl: string): boolean => currentUrl?.replace('/', '')?.startsWith(urlConfig);
        const path = startsWith(route.routeConfig?.path as string);
        const url = startsWith(this.router?.url);

        if ((url && !path) || (!url && path)) this.menuServ.reorderMenu.next(true);
    }

    private goToLogin(loginPath: string, nextPath: string): void {
        const pathname = window.location.pathname?.trim();
        const nextUrl = pathname && pathname !== "/" ? pathname : null;
        const params = {queryParams: {[nextPath]: nextUrl}};

        this.router.navigate([loginPath], nextUrl ? params : {});
    }
}
