import { Injectable } from '@angular/core';

import { Observable, of, Subject } from "rxjs";
import { delay } from "rxjs/operators";

import { GlobalLoading } from "./models/global-loading";

@Injectable({
    providedIn: 'root'
})
export class GlobalLoadingService {
    private globalLoadingSubject = new Subject<GlobalLoading>();
    private show = false;

    watch(): Observable<GlobalLoading>{
        return this.globalLoadingSubject.asObservable();
    }

    send(globalLoading: GlobalLoading): void{
        this.globalLoadingSubject.next(globalLoading);
    }

    setShow(show: boolean): void{
        this.show = show;
    }

    getShow(): Observable<boolean>{
        return of(this.show).pipe(delay(100));
    }

    isShowing(): boolean{
        return this.show;
    }
}
