<mat-form-field [appearance]="appearance"
                [floatLabel]="floatLabel"
                [formGroup]="form"
                [hintLabel]="hintLabel | translate"
                class="date-range">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-date-range-input [dateFilter]="filter"
                          [rangePicker]="dateRange"
                          [required]="required"
                          [separator]="separator | translate">
        <input #startInput
               (dateChange)="dateServ.onChange(startInput, formatDate, startField); skipToNextInput()"
               (dateInput)="dateServ.onChange(startInput, formatDate, startField); skipToNextInput()"
               [attr.aria-label]="initPlaceholder | translate"
               [formControlName]="startName"
               [accFormDate]="textMaskConfig"
               [name]="startName"
               [placeholder]="initPlaceholder | translate"
               [required]="required"
               aria-label=""
               autocomplete="chrome-off"
               matStartDate>
        <input #endInput
               (dateChange)="dateServ.onChange(endInput, formatDate, endField)"
               (dateInput)="dateServ.onChange(endInput, formatDate, endField)"
               [attr.aria-label]="finalPlaceholder | translate | lowercase"
               [formControlName]="endName"
               [accFormDate]="textMaskConfig"
               [name]="endName"
               [placeholder]="finalPlaceholder | translate | lowercase"
               [required]="required"
               aria-label=""
               autocomplete="off"
               matEndDate>
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="dateRange" matSuffix></mat-datepicker-toggle>
    <mat-date-range-picker #dateRange
                           (monthSelected)="onMonthSelected($event, dateRange, date)"
                           (yearSelected)="onYearSelected($event, dateRange, date)"
                           [startView]="startView">
    </mat-date-range-picker>
    <mat-icon *ngIf="icon" aria-hidden="true" matSuffix>{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(startField) || formsHelper.isInvalidField(endField)">
        {{ (formsHelper.getErrorMessage(startField) | async) || (formsHelper.getErrorMessage(endField) | async) }}
    </mat-error>
</mat-form-field>
