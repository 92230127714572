export const MOBILE_VERSIONS_TRANSLATE = {
	mobileVersions: {
		messages: {
            inactive: "Versão mobile inativado com sucesso",
			delete: "Versão mobile inativado com sucesso",
			update: "Versão mobile removida com sucesso",
            recover: "Versão mobile reativada com sucesso",
            create: "Versão mobile criada com sucesso"

		},
		list: {
			confirmInactivateDialog: {
				title: "Inativar versão mobile?",
				body: "Tem certeza que deseja inativar a versão {{version}}?",
				actions: {
					inactivate: "Sim, inativar",
					back: "Voltar",
				},
			},
			confirmDeleteDialog: {
				title: "Atenção!",
				body: "Deseja remover a versão mobile?",
			},
			table: {
				columns: {
					id: "ID",
                    androidVersion: "Código da versão",
                    applicationType: "Tipo de dispositivo",
                    updateType: "Atualização",
                    productType: "Produto"
				},
			},
			listActions: {
				inactivate: {
					text: "Inativar",
				},
				activate: {
					text: "Ativar",
				},
			},
			filters: {
				fields: {
					// id: {
					// 	label: "ID",
					// 	placeholder: "Informe aqui o ID",
					// 	url: "id",
					// },
                    versionCode: {
						label: "Código da versão",
						placeholder: "Informe aqui o código da versão",
						url: "version-code",
					},
                    applicationType: {
						label: "Tipo",
						placeholder: "Informe aqui o tipo de dispositivo",
						url: "application-type",
					},
                    updateType: {
                        label: "Atualização",
                        placeholder: "Informe aqui a atualização",
                        url: "update-type",
                    },
                    productType: {
                        label: "Produto",
                        placeholder: "Informe aqui o produto",
                        url: "product-type",
                    },
				},
			},
		},
	    create: {
			form: {
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		update: {
			form: {
				actions: {
					save: {
						text: "Salvar"
					}
				}
			}
		},
		read: {
			tabs: {
				data: "Dados",
				// users: "Usuários"
			}
		},
		save: {
			form: {
				// subTitle: "Crie o seu termo de forma dinâmica.",
				fields: {
                    androidVersion: {
						label: "Código da versão",
						placeholder: "Informe aqui o código da versão"
					},
                    iosVersion: {
                        label: "Código da versão",
                        placeholder: "Informe aqui o código da versão"
                    },
                    applicationType: {
						label: "Tipo",
						placeholder: "Informe aqui o tipo de dispositivo"
					},
                    updateType: {
                        label: "Atualização",
                        placeholder: "Informe aqui a atualização"
                    },
                    productType: {
                        label: "Produto",
                        placeholder: "Informe aqui o produto"
                    },
				},
			}
		},
	},

};


