<button #fab
        *ngIf="!href"
        [matTooltip]="fab.offsetWidth <= 60 ? (text | translate) : ''"
        class="fab mdc-fab mdc-fab--extended fab"
        matRipple
        matRippleColor="rgba(255, 255, 255, 0.12)">
    <ng-container *ngTemplateOutlet="into"></ng-container>
</button>

<a #fab
   *ngIf="href"
   [matTooltip]="fab.offsetWidth <= 60 ? (text | translate) : ''"
   [routerLink]="href | translate"
   class="fab mdc-fab mdc-fab--extended fab"
   matRipple
   matRippleColor="rgba(255, 255, 255, 0.12)">
    <ng-container *ngTemplateOutlet="into"></ng-container>
</a>

<ng-template #into>
    <div aria-hidden="true" class="mdc-fab__ripple"></div>
    <span *ngIf="icon" aria-hidden="true" class="material-icons mdc-fab__icon fab__icon">{{ icon }}</span>
    <span *ngIf="text" class="mdc-fab__label fab__text">{{ text | translate }}</span>
</ng-template>

