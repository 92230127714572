<mat-form-field [appearance]="appearance"
                [floatLabel]="floatLabel"
                [formGroup]="form"
                [hintLabel]="hintLabel | translate"
                class="input">
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>

    <input #formInputDefault
           (focus)="onFocus($event)"
           (input)="upperCase(formInputDefault)"
           *ngIf="!isMoney && !mask"
           [attr.aria-label]="label | translate"
           [attr.inputmode]="inputMode"
           [email]="type === 'email'"
           [formControlName]="name"
           [accAutofocus]="autofocus"
           [maxlength]="maxlength"
           [name]="name"
           [placeholder]="placeholder | translate"
           [required]="required"
           [suffix]="suffix"
           [type]="getType(type)"
           aria-label=""
           autocomplete="chrome-off"
           matInput
           spellcheck="false">

    <input #formInputMask
           (focus)="onFocus($event)"
           (input)="upperCase(formInputMask)"
           (keypress)="onKeypress($event)"
           *ngIf="!isMoney && mask"
           [attr.aria-label]="label | translate"
           [attr.inputmode]="inputMode"
           [dropSpecialCharacters]="dropSpecialCharacters"
           [formControlName]="name"
           [leadZeroDateTime]="leadZeroDateTime"
           [accAutofocus]="autofocus"
           [mask]="mask"
           [maxlength]="maxlength"
           [name]="name"
           [placeholder]="placeholder | translate"
           [required]="required"
           [suffix]="suffix"
           [type]="type"
           aria-label=""
           autocomplete="off"
           matInput
           spellcheck="false">

    <input #formInputMoney
           (focus)="onFocus($event)"
           (input)="upperCase(formInputMoney)"
           *ngIf="isMoney && !mask"
           [attr.aria-label]="label | translate"
           [attr.inputmode]="inputMode"
           [email]="type === 'email'"
           [formControlName]="name"
           [accAutofocus]="autofocus"
           [maxlength]="maxlength"
           [name]="name"
           [options]="moneyOptions"
           [placeholder]="placeholder | translate"
           [required]="required"
           [type]="getType(type)"
           aria-label=""
           autocomplete="off"
           currencyMask
           matInput
           spellcheck="false">

    <mat-icon *ngIf="!loading && icon" aria-hidden="true" class="input__icon" matSuffix>{{ icon }}</mat-icon>
    <acc-form-button *ngIf="isPassowordType(type)"
                    [click]="toggleHide.bind(this)"
                    [icon]="hide ? 'visibility_off' : 'visibility'"
                    [text]="hide ? ('form.input.password.show' | translate) : ('form.input.password.hide' | translate)"
                    classes="input__password-button"
                    color="none"
                    matSuffix
                    theme="icon">
    </acc-form-button>
    <acc-progress-spinner [condition]="loading"
                          [diameter]="20"
                          [strokeWidth]="2"
                          aria-hidden="true"
                          class="input__loading"
                          classes="input__progress-spinner"
                          matSuffix
                          mode="indeterminate">
    </acc-progress-spinner>
    <span *ngIf="showMoneyPrefix()" class="input__money-prefix" matPrefix>{{ moneyPrefix }}&nbsp;</span>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-hint *ngIf="maxlength" align="end" class="input__nowrap">{{ showLengthField() }}/{{ maxlength }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
