export const FILE_PICKER_TRANSLATE = {
    filePicker: {
        actions: {
            title: "Anexo",
            download: "Abrir anexo {{ filename }}",
            remove: "Remover anexo {{filename}}",
            addMedia: "Adicionar anexo",
            changeMedia: "Substituir anexos",
        },
        noResults: {
            label: "Nenhum anexo adicionado"
        },
    },
};


