import {Injectable} from '@angular/core';
import {Translator} from "../pipes/utils/root-translate.pipe";
import { Generic } from '../models/generic';
import { Translate } from '../helpers/translate';

@Injectable({
	providedIn: 'root'
})
export class TranslatorService implements Translator {
	private static join(value: string, root = '') {
		if(!value) return root ?? '';
		if(!root) return value ?? '';

		return `${root}.${value}`;
	}

	value(value = "", params?: Generic, root = ''): string {
		return Translate.value(TranslatorService.join(value, root), null, params);
	}

	create(translationRoot = '', translationParams?: Generic): Translator {
		return {
			value: (value = "", params: Generic = {}) =>
				this.value(value, {
					...translationParams,
					...params
				}, translationRoot),
			create: (root = '', params: Generic = {}) =>
				this.create(TranslatorService.join(root, translationRoot),
					{
						...translationParams,
						...params
					}
				)
		};
	}
}
